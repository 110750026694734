import React, { Component } from "react";
import SidebarHeader from "./SidebarHeader";
import Overlay from "@threeskye/core-components/dist/components/Overlay";
import Button from "@threeskye/buttons"

import "./Sidebar.scss";

class Sidebar extends Component {
	constructor(props) {
		super(props);
		this.overlayClick = this.overlayClick.bind(this);
	}

	overlayClick() {
		this.props.onOverlayClick && this.props.onOverlayClick();
	}

	render() {
		let sidebarContainer = "sidebar-container";
		let footerButtonText = this.props.footerButtonText;
		let footerButton;

		if (this.props.left) {
			sidebarContainer += " left";
		}
		if(this.props.classNames) {
			sidebarContainer += " " + this.props.classNames;
		}
		if (this.props.visible) {
			sidebarContainer += " sidebar-fadein";
		}
		if(this.props.footerButton) {
			footerButton = <Button fullWidth icon={this.props.footerButtonIcon} onClick={this.props.footerButtonClick}>{footerButtonText}</Button>
		}
		if(this.props.buttonToggles) {
			var buttonToggles = <div className="sidebar-toggle-container">
									<div className="sidebar-toggles">
										<span className="activeToggle">{this.props.buttonLeft}</span>
										<span>{this.props.buttonRight}</span>
									</div>
								</div>
		}

		return (
			<Overlay visible={this.props.visible} onClick={this.overlayClick}>
				<div className={sidebarContainer} onClick={e=>e.stopPropagation()}>
				<SidebarHeader { ... this.props } />
					{buttonToggles}
					{this.props.children}
					<div className="sidebar-footer">
						{footerButton}
						<Button onClick={this.props.onClose} classNames="mt-1" icon="close" outlined fullWidth>Close</Button> 
					</div>
				</div>
			</Overlay>
		)
	}
}

export default Sidebar;
