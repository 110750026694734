import React, { useState, useEffect } from 'react'
import AsyncSelect from 'react-select/lib/Async';
import { components } from 'react-select';
import "./TokenField.scss"
import SelectClearIcon from './SelectClearIcon';
import ClientAccountToken, { ClientAccountTokenSizes } from '../ClientAccount/ClientAccountToken';
import ToolTip from '../../components/Popups/Tooltip'
import ClientAccountLink from '../ClientAccount/ClientAccountLink';

const ClientOrAccountTokenField = (props) => {
	const { isTemplate, removeSingleAccount, isRemovable, onClientUpdate, onAccountUpdate, matchClients, matchAccounts,
		displayClient, displayAccount, acceptSuggestion, accountSuggestions, clientSuggestions, accountList, clientList,
		isEmailTemplate, placeholder, getFocused, forwardRef, noOptionsMessage, customIcon, minWidth, width,
		rightExtExpandedFull, taggedOrder, turnOffCashOptions } = props
	let lastFoundMatches = []
	let lastFoundSearchCounter = 0

	const [isEmpty, setIsEmpty] = useState(true)
	const [hideLabel, setHideLabel] = useState(false)
	const [accountWidth, setAccounWidth] = useState(null)

	useEffect(() => {
		const templateAccountTaggedWidth = document.getElementById("emtf-client-account-token-wrapper").clientWidth
		if (templateAccountTaggedWidth) setAccounWidth(templateAccountTaggedWidth)
	}, [accountList.length])


	//Overwriting this behavior, keeps the menu closed when the user has erased text
	//The default behavior of this will leave all of the options visible if a user does this
	function handleInputChange(input, actionInfo) {
		if (isTemplate) {
			if (actionInfo.action === "set-value") {
				// clear state when tagging an account
				removeSingleAccount()
				setHideLabel(false)
			}
			if (actionInfo.action === "input-change") {
				// hide tagged account when serching an account
				if (input.length > 0) setHideLabel(true)
				else setHideLabel(false)
			}
			let isEmptyInput = input == null || input.length < 1;
			setIsEmpty(isEmptyInput)
		} else {
			let isEmptyInput = input == null || input.length < 1;
			setIsEmpty(isEmptyInput)
		}
	}
	//Due to an issue with updates conflicting with each other
	//we need to detect which of the lists was changed
	function onChange(val, { action, removedValue }) {
		let clients = [];
		let accounts = [];
		const value = Array.isArray(val) ? val : [val]

		value.forEach(item => {
			if (item.listObjectType === "client") {
				clients.push(item);
			} else {
				accounts.push(item);
			}
		})

		switch (action) {
			case 'remove-value':
			case 'pop-value':
				if (removedValue) {
					if (!isRemovable(removedValue)) {
						return;
					}
					if (removedValue.listObjectType === "client")
						onClientUpdate(clients, removedValue);
					else
						onAccountUpdate(accounts, removedValue);
				}
				break;
			default:
				//At this step we need to check which list needs updateing
				//final index is allways what we want
				if (value[value.length - 1].listObjectType === "client")
					onClientUpdate(clients, null);
				else
					onAccountUpdate(accounts, null);
		}

	}

	//Look for matches in both
	function findMatches(input) {
		return Promise.all([
			Promise.resolve(matchClients(input)),
			Promise.resolve(matchAccounts(input))
		]).then(values => {
			let merge = [];
			for (let i = 0; i < values[0].length; i++) {
				merge.push(Object.assign({
					listObjectType: "client"
				}, values[0][i]))
			}

			if (!taggedOrder || (taggedOrder && accountList.length === 0)) {
				for (let i = 0; i < values[1].length; i++) {
					merge.push(Object.assign({
						listObjectType: "account"
					}, values[1][i]))
				}
			}
			if (merge.length > 0 && merge[0].searchCounter && merge[0].searchCounter > lastFoundSearchCounter) {
				lastFoundSearchCounter = merge[0].searchCounter;
				lastFoundMatches = merge;
			} else if (merge.length > 0 && merge[0].searchCounter && merge[0].searchCounter < lastFoundSearchCounter) {
				return lastFoundMatches;
			}
			return merge;
		})
	}

	function optionLabel(option) {
		if (option.listObjectType === "client")
			return displayClient(option);
		return displayAccount(option);

	}

	function getOptionValue(option) {
		if (option.listObjectType && option.data && option.data.id)
			return option.listObjectType.concat(option.data.id)
		return JSON.stringify(option);
	}


	function checkForUniqueSuggestions(arr, target) {
		return target.every(v => arr.includes(v))
	}

	function renderSuggestions() {
		if ((accountSuggestions && accountSuggestions.length > 0) || (clientSuggestions && clientSuggestions.length > 0)) {

			let checkAccounts = checkForUniqueSuggestions(accountSuggestions, accountList)
			let checkClients = checkForUniqueSuggestions(clientSuggestions, clientList)

			if (checkAccounts || checkClients) {
				return (
					<div className="token-field-suggestions">
						<div className="suggestion-label-container">
							<span className="suggestion-label">Suggestions: </span>
						</div>

						{accountSuggestions.map((suggest, index) => {

							if (accountList.some(e => e.data.id === suggest.data.id)) return null
							return (
								<ToolTip popupElementClass='token-field-suggestions-popup' label={<><span className="account-type" >{suggest.data.accountType.name + " "} </span><span >{suggest.data.number}</span></>}>
									<ClientAccountToken margin="small" onClick={() => acceptSuggestion(index, "account")} key={index} clientAccount={suggest.data} size={ClientAccountTokenSizes.SMALL} />
								</ToolTip>
							)
						})}
						{clientSuggestions.map((suggest, index) => {
							if (clientList.some(e => e.data.id === suggest.data.id)) return null
							return (
								<ToolTip key={index} popupElementClass='token-field-suggestions-popup' label={suggest.data.userId}>
									<ClientAccountToken margin="small" onClick={() => acceptSuggestion(index, "client")} key={index} clientAccount={suggest.data} size={ClientAccountTokenSizes.SMALL} />
								</ToolTip>
							)
						})}
					</div>
				)
			}
		}
		else return null;
	}

	function renderSelect() {
		let values = [];
		for (let i = 0; i < accountList?.length; i++) {
			values.push(Object.assign({
				listObjectType: "account"
			}, accountList[i]));
		}
		for (let i = 0; i < clientList?.length; i++) {
			values.push(Object.assign({
				listObjectType: "client"
			}, clientList[i]))
		}
		let styles = {
			multiValue: base => { return { ...base, margin: "1px 1px" } },
			placeholder: base => { return { ...base, marginLeft: "0.5em" } },//When removing the margin from the input, add it back to the placeholder
			input: base => {
				return {
					...base,
					paddingBottom: 0,
					paddingTop: 0,
					margin: 0,
					color: isTemplate && hideLabel === false && accountList.length > 0 ? "#fff" : "hsl(0, 0%, 20%)",
					caretColor: isTemplate && hideLabel === false && accountList.length > 0 ? "#fff" : "hsl(0, 0%, 20%)",
				}
			},//TODO CM the internal input field uses this for styling
			multiValueRemove: (base, state) => { return isRemovable(state.data) ? base : { ...base, display: "none" } }
		}
		// on template tag account: hide selected tag account when serching accounts
		if (hideLabel) values = []

		return (
			<AsyncSelect
				{...props}
				onFocus={() => isEmailTemplate ? getFocused(true) : null}
				onBlur={() => isEmailTemplate ? getFocused(false) : null}
				ref={forwardRef && forwardRef}
				//fixed values
				isMulti
				cacheOptions={!turnOffCashOptions}
				isClearable={false}
				className="token-field cat-token-field"
				classNamePrefix="token-field"
				placeholder={placeholder ? placeholder : "Tag Account or Client"}
				noOptionsMessage={noOptionsMessage}
				//menu control
				menuIsOpen={!isEmpty}
				openMenuOnClick={false}
				//bound methods
				onInputChange={handleInputChange}
				onChange={onChange}
				loadOptions={findMatches}
				getOptionLabel={optionLabel}
				//This MUST stay. used to compare the value currently selected to a new input
				getOptionValue={getOptionValue}
				//prop methods/values
				value={values}
				//component overrides
				styles={styles}
				autoFocus={isEmailTemplate ? true : false}
				components={
					{
						DropdownIndicator: () => null,
						IndicatorSeparator: () => null,
						// we will need custom rendering for the components
						MultiValueContainer: (innerprops) => {
							return (
								<>
									<div ref={innerprops.innerRef} style={{ minWidth: 0 }}>
										<components.MultiValueContainer {...innerprops} />
									</div>
								</>
							);
						},
						MultiValueLabel: (innerprops) => {
							return (
								<div style={{ minWidth: 0 }}>
									<ClientAccountLink
										customIcon={customIcon ? customIcon(innerprops) : null}
										clientAccount={innerprops.data.data}
										size={ClientAccountTokenSizes.SMALL}
										tdWidth={rightExtExpandedFull ? null : typeof minWidth === "number" ? minWidth - 80 : accountWidth && isTemplate ? accountWidth - 80 : null}
									/>
								</div>
							);
						},
						MultiValueRemove: (innerprops) => {
							return <components.MultiValueRemove {...innerprops}><SelectClearIcon /></components.MultiValueRemove>
						},
					}
				}

			/>
		);
	}
	//split out the suggestions from the core
	return (
		<div id={"emtf-client-account-token-wrapper"} className={`token-field-container client-and-account-tag-field ${isTemplate ? "template-account-tag-field" : ""}`} style={minWidth && !rightExtExpandedFull ? { minWidth: minWidth } : { width: width || "" }}>
			{renderSelect()}
			{renderSuggestions()}
		</div>
	)

}
export default ClientOrAccountTokenField;
