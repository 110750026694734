import React, { Component } from 'react';
import { withI18n, withRemote, withStorage } from "@threeskye/global";
import Button from "@threeskye/buttons";

import './ForgotPasswordPage.scss';
import './LoginForm.scss';

import logo from './logo.png';
import CRMErrorMessage from '../Shared/Validation/CRMErrorMessage';

class ForgotPasswordCodeEntry extends Component {

	codeInputs = [];
	constructor(props) {
		super(props);

		this.state = {
			username: this.props.storage.get("username"),
			password: "",
			failLogin: false,
			code: "",
			retry: 3,
			key: this.props.storage.get("password_change_key"),
			tooShort: true
		}

		this.onSubmitCode = this.onSubmitCode.bind(this);
		this.onCodeEnter = this.onCodeEnter.bind(this);

		this.initialise = this.initialise.bind(this);
		this.inputFocus = this.inputFocus.bind(this);
		this.handleKeyDown = this.handleKeyDown.bind(this);
		this.initialise();
	}

	initialise() {
		if (this.props.storage.get("username") === null || this.props.storage.get("password_change_key") === null) {
			this.props.switchPage("");
		}
	}

	handleKeyDown(e, idx) {
		if (e.key === 'Enter') {
			this.onSubmitCode();
		}
	}

	onSubmitCode() {
		const code = this.state.code;
		if (code.length !== 6) {
			console.log("Please enter entire code");
			return;
		}

		const data = {
			username: this.state.username,
			code: code,
			key: this.state.key
		};

		this.props.remote.post("/um/password-recovery-confirm", data)
			.then(x => {
				if (x.status === 'valid') {
					this.props.storage.put("password_change_key", x.key);
					this.props.storage.put("password_change_code", x.code);
					this.props.switchPage("reset-password/");
				}
			}, x => {
				//error
				if (this.state.retry <= 1) {
					this.props.storage.put("password_change_key", "");
					this.props.storage.put("password_change_code", "");
					this.props.switchPage("invalid-code/");
				}
				this.setState({ retry: this.state.retry - 1 });
				this.setState({ failLogin: true });
			});


	}

	inputFocus() {
	}

	onCodeEnter(val) {
		const tooShort = val.length !== 6;
		this.setState({ code: val, tooShort });
	}

	recoveryMethodResponsiveText() {
		if (this.props.recoveryMethod == "email") {
			return (<p>A unique code has been sent to your email.<br />
				Enter it below to verify the account for <strong>{this.state.username}</strong></p>
			)
		} else {
			return (<p>A unique code has been sent to your mobile.<br />
				Enter it below to verify the account for <strong>{this.state.username}</strong></p>)
		}
	}

	render() {
		const i = this.props.i18n.get;
		let fieldValidationMessage;
		const invalidCode = this.state.failLogin;
		if (this.state.failLogin) {
			fieldValidationMessage = i("CodeInvalid{" + this.state.retry + "}");//"Sorry, this code is invalid. Please try again. You have " + this.state.retry + " attempts remaining.";
		}


		return (
			<div id="master-container">
				<div id="header">
					<div className="logo-container">
						<img src={logo} alt="logo" className="logo" />
					</div>
				</div>
				<div id="content">
					<div id="welcome-message" className="content-wrapper">
						<h1 className="mb-1">Reset password</h1>
						{this.recoveryMethodResponsiveText()}
					</div>
					<div className="form modal-form">
						<div className="form-row justify-center">
							<div className="form-col-grow">
								{/* <h2 className="verification-input-label">Verification Code</h2> */}
								<CRMErrorMessage message={fieldValidationMessage} isValid={!invalidCode}>
									<div className="validation-code">
										<input autocomplete="off" className="twofa-code-input" placeholder="Verification Code" name="verification-code" value={this.state.code} onChange={(e) => this.onCodeEnter(e.target.value)} onKeyDown={(e, idx) => this.handleKeyDown(e, idx)} />
									</div>
								</CRMErrorMessage>
							</div>
						</div>
						<div className="form-row mt-3">
							{/* <div className="form-col-grow"> */}
								{/* TODO wire this button <Button icon="refresh" fullWidth outlined>Resend code</Button> */}
							{/* </div> */}
							<div className="form-col-grow">
								<Button icon="exit_to_app" fullWidth onClick={this.onSubmitCode} disabled={this.state.tooShort}>Submit</Button>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withStorage(withRemote(withI18n(ForgotPasswordCodeEntry)));
