import React, { Component } from 'react';
import { withRemote, withLoading } from '@threeskye/global';
import Link from './crm/Link';
import ThreeSkyeCRM from './crm/ThreeSkyeCRM';
import { Redirect, Switch, Route } from "react-router-dom";
import LoadingIcon from '@threeskye/loading-icon';
// import Dashboard from './components/Dashboard/Dashboard';

class ViewSelector extends Component {

	constructor(props) {
		super(props);

		this.init.bind(this)();
		this.buildFromConfig = this.buildFromConfig.bind(this);
		this.buildHeaders = this.buildHeaders.bind(this);
		this.buildNav = this.buildNav.bind(this);
		this.buildStateFromConfig = this.buildStateFromConfig.bind(this);

		this.state = {
			child: null,
			config: null,
			headers: [],
			contents: []

		}
	}

	init() {
		this.props.loading(true);


		this.props.remote.get("/modules/crm/view-builder")
			.then(view => {
				const { config } = view;
				this.buildStateFromConfig(config);
				this.setState({ config });
				this.props.loading(false);
			});

	}

	buildStateFromConfig(config) {
		const headers = this.state.headers;
		const contents = [];// this.state.contents;

		let navCount = 0;

		const promises = [];
		config.navigation.forEach(nav => {
			if (!nav || !nav.paths) {
				return;
			}
			nav.paths.forEach((path, idx) => {
				let contentsIndex = navCount + idx;
				if (path.header) {
					const route = {
						path: "/" + nav.pathPrefix + path.path
					}

					if (path.exactPath) {
						route.exact = true;
					};
					const pathToComponent = path.header.component ? path.header.component : path.header;
					import("./components/" + pathToComponent).then(module => {
						const Component = path.header.export ? module[path.header.export] : module.default;
						if (path.header.props) {
							route.render = () => (<Component {...path.header.props} />);
						} else {
							route.component = Component;
						}
						headers[contentsIndex] = route;
						this.setState({ headers });
					});
				}
				if (path.content) {

					const route = typeof path.path !== 'undefined' ? {
						path: "/" + nav.pathPrefix + path.path
					} : {}
					if (path.exactPath) {
						route.exact = true;
					}
					if (path.default) {
						route.default = true;
					}

					if (path.content.redirect) {
						route.render = props => {
							const redirectTo = path.content.redirect.split("/")
								.map(el => {
									if (el.startsWith(":")) {
										const param = el.substring(1);
										return props.match.params[param];
									} else {
										return el;
									}
								})
								.join("/");
							return <Redirect to={redirectTo} />;
						};
						contents[contentsIndex] = route;
						//this.setState({contents});
						return;
					}


					const pathToComponent = path.content.component ? path.content.component : path.content;
					promises.push(import("./components/" + pathToComponent).then(module => {
						const Component = path.content.export ? module[path.content.export] : module.default;
						if (path.content.props) {
							route.render = () => (<Component {...path.content.props} />);
						} else {
							route.component = Component;
						}
						contents[contentsIndex] = route;
						//this.setState({contents});
					}));
				}
			});

			navCount += nav.paths.length;
		});

		const dataPromise = import("./data/" + config.dataImplementation)
			.then(module => module.default);

		//We need to route all extensions even though they may not be relevant for any given "X"
		this.props.storage.getOrFetch("/modules/crm/provider/extensions/all").then(exts => {
			//Make sure all the "core" data is loaded, THEN start looking for extensions
			Promise.all(promises).then(() => {
				const extPromises = [];
				exts.forEach(ext => {
					const route = {
						path: ext.route,
						component: null
					};
					//Extensions always go at the beginning because there will be catchalls later on..
					contents.splice(0, 0, route);
					extPromises.push(import("./components/" + ext.component).then(module => {
						route.component = ext.export ? module[ext.export] : module.default;
						//this.setState({contents});
					}));
				});
				Promise.all(extPromises).then(() => {
					dataPromise.then(dataImplementation => {
						this.setState({ contents, dataImplementation });
					});
				});
			});
		});

	}

	buildNav() {
		/** Note nav needs to be an array (rather than embedded components), otherwise downstream breaks */
		const navigation = [];
		this.state.config.navigation.forEach(nav => {
			if (nav.excludeFromNav) {
				return;
			}
			navigation.push(<Link default={nav.default} pathPrefix={nav.pathPrefix} icon={nav.icon} textKey={nav.textKey} key={nav.pathPrefix} />)
		});
		return navigation;
	}

	buildHeaders() {
		const headers = [];
		this.state.headers.forEach(header => headers.push(<Route key={header.path} {...header} />));
		return headers;
	}

	buildContents() {
		const contents = [];
		this.state.contents.forEach((content, idx) => contents.push(<Route key={typeof content.path === 'undefined' ? 'undefinedPath' + idx : content.path} {...content} />));
		return contents;
	}

	buildFromConfig() {

		if (!this.state.dataImplementation) {
			return <div style={{ display: "flex", alignItems: "center", height: "100vh", marginTop: -18 }}><LoadingIcon centered size={80} /></div>;
		}
		const headers = this.buildHeaders();
		const contents = this.buildContents();
		const nav = this.buildNav();

		return (
			<ThreeSkyeCRM
				{... this.props}
				nav={nav}
				accountsListKey="/modules/crm/provider/accounts"
				clientsListKey="/modules/crm/provider/clients"
				dataImplementation={this.state.dataImplementation}
				headers={
					// <Headers>
						<Switch>
							{headers}
						</Switch>
					// </Headers>
				}
				contents={
					<>
						{/* <Contents> */}
							<Switch>
								{contents}
							</Switch>
						{/* </Contents> */}
					</>
				}
			/>
		)
	}

	render() {
		if (this.state.config) {
			return this.buildFromConfig();
		}

		const Child = this.state.child;

		return Child && <Child {... this.props} />
	}
}

export default withLoading(withRemote(ViewSelector));