import React, {Component} from 'react';
import {withI18n, withRemote, withStorage} from "@threeskye/global";
import Button from "@threeskye/buttons";

import './SetPasswordPage.scss';
import logo from './logo.png';
import { withCookies } from 'react-cookie';

class SetPasswordPage extends Component {

	constructor(props) {
		super(props);

		this.state = {
			username: this.props.storage.get("username"),
			key: this.props.storage.get("password_change_key"),
			code: this.props.storage.get("password_change_code"),
			password: "",
			password1: "",
			failLogin: false,
			passwordMatch: true
		}
		this.initialise = this.initialise.bind(this);
		this.onChangePassword = this.onChangePassword.bind(this);
		this.onChangePassword1 = this.onChangePassword1.bind(this);
		this.onPasswordSubmit = this.onPasswordSubmit.bind(this);
		this.initialise();
	}

	initialise() {
		if (this.props.storage.get("username") === null || this.props.storage.get("password_change_key") === null) {
			this.props.switchPage("");
		}
	}

	onChangePassword(val) {
		this.setState({password: val});
	}
	onChangePassword1(val) {
		this.setState({password1: val});
	}


	onPasswordSubmit() {

		let pw = this.state.password;
		if (pw !== this.state.password1) {
			this.setState({passwordMatch:false, errorMessage: "Passwords did not match"});
			return;
		} else {
			//Check strength
			let count = pw.match(/[a-z]/) ? 1 : 0;
			count += pw.match(/[A-Z]/) ? 1 : 0;
			count += pw.match(/[0-9]/) ? 1 : 0;
			count += pw.match(/[^A-Za-z0-9]/) ? 1 : 0;
			if (count < 3) {
				this.setState({passwordMatch: false, errorMessage: "Password must contain at least three of lower case, upper case, numeric and non alphanumeric characters"});
				return false;
			}
			if (pw.length < 8) {
				this.setState({passwordMatch: false, errorMessage: "Password must be at least 8 characters"});
				return false;
			}

			this.setState({passwordMatch:true});

			const data = {
					username: this.state.username,
					password: this.state.password,
					key: this.state.key,
					code: this.state.code
			}

			this.props.remote.post("/um/update-password", data, {rawResponse: true})
					.then(x=>{
						//Cookie is now set serverside...
						//Cookie so we don't immediately get asked for another code
						// let cookies = this.props.cookies;
						// let username = this.state.username.replace("@", "#");
						// let cookieName = '3skye.login.'+username;
						// if (!cookies.get(cookieName)) {
						// 	if (x.cookie) {
						// 		const oneYear = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
						// 		cookies.set('3skye.login.'+username, x.cookie, { secure: true, path: '/', expires: oneYear});
						// 	}
						// }

						this.props.switchPage("password-changed/");
					}, x=>{
						//TODO error message
					});
		}
	}

	render() {
		const i = this.props.i18n.get;
		const invalidPasswords = this.state.passwordMatch ? "valid-field" : "invalid-field";

		return <div id="master-container">
			<div id="header">
				<div className="logo-container">
					<img src={logo} alt="logo" className="logo" />
				</div>
			</div>
			<div id="content">
				<div id="welcome-message" className="content-wrapper">
					<h1 className="mb-1">Reset password</h1>
					<p>Enter a new password. Passwords must be at least eight characters long and must contain at least three of lower case, upper case, numeric and non alphanumeric characters.</p>
				</div>
				<div id="login-form" className={invalidPasswords + " form modal-form"}>
					<div className="invalid-field-message text-align-center mt-2">{this.state.errorMessage}</div>
					<div className="form-row">
						<div className="form-col-grow">
							<label htmlFor="password">{i("NewPassword")}</label>
							<input type="password" placeholder="New Password" className="input-field" required onChange={e=>this.onChangePassword(e.target.value)} />								
						</div>
					</div>
					<div className="form-row">
						<div className="form-col-grow">
							<label htmlFor="confirm-password">Confirm Password</label>
							<input type="password" placeholder="Confirm password" className="input-field" required onChange={e=>this.onChangePassword1(e.target.value)} />
						</div>
					</div>
					<div className="form-row mt-3">
						<div className="form-col-grow">
							<Button fullWidth onClick={this.onPasswordSubmit}>Submit</Button>
						</div>
					</div>
				</div>
			</div>
		</div>;
	}
}

export default withCookies(withStorage(withRemote(withI18n(SetPasswordPage))));
