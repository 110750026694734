export function displayJSONField(value) {
	if (typeof value === "string") {
		return value;
	} else if (value === null || value === undefined) {
		return "[Empty]";
	} else if (typeof value === "object") {
		const keys = Object.keys(value);
		if (keys.length > 1) {
			return displayJSONField(value.value || value.data || value[keys[0]]);
		}
	}
	return JSON.stringify(value);
}

//used to hide fields
const excluded = { id: true };

export function getAllFieldNames(obj1, obj2) {
	return Array.from(
		[obj1, obj2]
			.filter((obj) => !!obj)
			.reduce((set, obj) => {
				Object.keys(obj).forEach((key) => {
					if (!excluded[key]) {
						set.add(key);
					}
				});
				return set;
			}, new Set())
	);
}
