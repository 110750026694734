import React, { Component } from 'react';
import "./Icons.scss";

class XlsIcon extends Component {
	render(){
		const fillA = { fill: "#2BA985" };
		const fillB = { fill: "#6ECDB2" };
		const fillC = { fill: "#278C6F" };

		return (
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
				<defs></defs>
				<g id="Layer_1" data-name="Layer 1">
					<path
						id="Shape_Copy_386"
						data-name="Shape Copy 386"
						style={fillA}
						d="M20.15,23.43H6.86a2.14,2.14,0,0,1-2.14-2.14V2.71A2.14,2.14,0,0,1,6.86.57H16.8L22.29,6V21.29A2.14,2.14,0,0,1,20.15,23.43Z"
					/>
					<path id="Shape_Copy_385" data-name="Shape Copy 385" style={fillB} d="M21.54,6.6H16.32V1.43Z" />
					<rect id="Rectangle_5_Copy_318" data-name="Rectangle 5 Copy 318" style={fillC} x="1.71" y="4.01" width="11.82" height="6.02" rx="2.14" />
					<path
						id="_copy_3-2"
						data-name=" copy 3-2"
						style={fillB}
						d="M17.52,12.82h-3v1.53h.75L13.7,15.63l-1.53-1.28h.77V12.82H9.87v1.53h.77l2.3,1.91-2.3,1.9H9.87V19.7h3.07V18.16h-.77l1.53-1.27,1.52,1.27h-.75V19.7h3V18.16h-.77l-2.28-1.9,2.28-1.91h.77Z"
					/>
					<g className="icon-text" id="MSG-3" style={fillC}>
						<g style={fillC}>
							<path d="M5.6,6.92l.9,1.23H5.88l-.59-.8-.59.8H4.09L5,6.93,4.09,5.7h.62l.59.8.59-.8H6.5Z" />
							<path d="M8.53,7.68v.47H6.78V5.7h.53v2Z" />
							<path
								d="M8.71,7.79,9,7.43a1.21,1.21,0,0,0,.81.32c.26,0,.4-.12.4-.28s-.14-.22-.52-.31-.88-.26-.88-.74a.8.8,0,0,1,.9-.76,1.39,1.39,0,0,1,1,.34l-.27.38a1.14,1.14,0,0,0-.7-.28c-.23,0-.36.12-.36.27s.15.23.52.32c.53.12.88.27.88.73s-.35.78-.95.78A1.6,1.6,0,0,1,8.71,7.79Z"
							/>
						</g>
					</g>
				</g>
			</svg>
		);
	}
}
export default XlsIcon;
