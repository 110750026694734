import React, { useState } from "react";
import determineNotificationParser from "./NotificationParser";
import "./NotificationList.scss";
import Divider from "../../../CRMDateRangeInput/components/Divider";


const ExpandableNotification = ({ title, onDelete, data, subtitle, triggered, icon, onClick, deleteOnClick, pinned, unread, type, className, children }) => {

	const [expanded, setExpanded] = useState(false)
	const { parseTitle, parseSubtitle } = determineNotificationParser(type);

	const onDeleteAll = (e) => {
		data.map(n => onDelete(e, n))
	}


	return (
		<li
			className={`notification-li notification-expandable${unread ? " notification-unread" : ""}${pinned ? " notification-pinned" : ""}${className ? " " + className : ""
				}${expanded ? " notification-expanded" : ""}`}
		>
			<div className="expandable-inner" onClick={() => setExpanded(!expanded)}>
				{icon && <i className={`material-icons notification-type-icon  ${type === "change" ? "assembled-change" : type}`}>{icon}</i>}
				<div className="notification-li-content">
					<div className="notification-li-title">
						<span>{parseTitle(title)}</span>
						<i className="material-icons expanded-icon" >
							{expanded ? "expand_less" : "expand_more"}
						</i>
					</div>
					{/* {subtitle !== "" && subtitle !== " " && subtitle && (
						<div className="notification-li-subtitle"> */}
					{/* {parseSubtitle(subheader)} */}
					{/* {subheader} days ago
							<i className="material-icons notification-expand-icon">expand_more</i>
						</div>
					)} */}
				</div>
				<div className="notification-actions">
					<i className="material-icons delete-icon" onClick={(e) => onDeleteAll(e)}>
						delete_forever
					</i>
					{/* <i className={`material-icons pinned-icon`} onClick={() => alert("Pin! 📌")}>
							push_pin
						</i> */}
				</div>
				{/* {deleteOnClick && (
					<i className="material-icons delete-notification" onClick={onCloseClick}>
						close
					</i>
				)} */}
			</div>
			{expanded && (
				<>
					<Divider />
					<div className="expandable-content">{children}</div>
				</>
			)}
		</li>
	);
}

export default ExpandableNotification;