import React, { useState } from 'react';
import TimeField from './TimeField';
import DateField from '@threeskye/core-components/dist/components/DateField/DateField';
import { withI18n } from '@threeskye/global';
import './DateTimeField.scss'
import { format } from 'date-fns';

const DateTimeField = (props) => {

    const [isNow, setIsNow] = useState(false)
    const [nowChecked, setNowChecked] = useState(false);
    const [date, setDate] = useState(null);
    const [time, setTime] = useState(null);

    React.useEffect(() => {
        if (props.date) {
            setDate(Date.parse(props.date));
        }
        if (props.time) {
            setTime(props.time);
        }
        if (props.isNow) {
            setIsNow(true);
            setNowChecked(true);
        } else {
            setIsNow(false);
            setNowChecked(false);
        }
    }, [])

    const onDateChange = (date) => {
        if (props.onDateChange)
            props.onDateChange(date);

        //Now?
        let now = new Date();
        now = format(now, "yyyy-MM-dd");
        if (!date || now !== date) {
            setIsNow(false);
            setNowChecked(false);
            if (props.onNowClick) {
                props.onNowClick(false)
            }
        }
    }

    const onTimeChange = (time) => {
        if (props.onTimeChange) {
            props.onTimeChange(time);
        }

        //Now?
        let now = new Date();
        if (!time || time !== formatTime(now)) {
            setIsNow(false);
            setNowChecked(false);
            if (props.onNowClick) {
                props.onNowClick(false)
            }
        }
    }

    const formatTime = (date) => {
        let hours = "" + date.getHours();
        if (hours.length === 1)
            hours = "0" + hours;

        let minutes = "" + date.getMinutes();
        if (minutes.length === 1)
            minutes = "0" + minutes;

        return hours + ":" + minutes;
    }

    const onNowClick = (e) => {
        if (nowChecked) {
            //Just uncheck it
            setNowChecked(false);
            if (props.onNowClick) {
                props.onNowClick(false)
            }
        } else {
            setIsNow(true);
            setNowChecked(true);
            setDate(new Date());
            //Fire
            if (props.onDateChange) {
                let now = new Date();
                props.onDateChange(format(now, "yyyy-MM-dd"));
            }
            if (props.onTimeChange) {
                let now = new Date();
                props.onTimeChange(formatTime(now))
            }
            if (props.onNowClick) {
                props.onNowClick(true)
            }
        }
    }


    return <div className="date-time-input-container">
        <DateField key={(date && date.toString()) || "init"} autoFocus={props.autoFocus ? true : false} onChange={onDateChange} isNow={isNow} date={date && format(date, "yyyy-MM-dd")} />
        <TimeField onChange={onTimeChange} isNow={isNow} time={time} />
        {
            props.nowButton ? <div className="datetime-now-button">
                <label className={`checkbox-field checkbox-container date-time-checkbox-clickable ${nowChecked ? " checkbox-active" : ""}`} onClick={() => onNowClick()}>
                    {/* <Checkbox type="checkbox" value="" name="" id="now-checkbox" checked={nowChecked} onChange={onNowClick} /> */}
                    {props.i18n.get("Now")}
                </label>
            </div> : ""
        }
    </div>
}

export default withI18n(DateTimeField);