import React, { Component } from 'react';
import "./Icons.scss";
class PngIcon extends Component {
	render(){
		const fillA = { fill: "#4EBDC7" };
		const fillC = { fill: "#1EAEBA" };
		const fillB = { fill: "#68D7E0" };

		return (
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
				<defs></defs>
				<g id="Layer_1" data-name="Layer 1">
					<path
						id="Shape_Copy_138"
						data-name="Shape Copy 138"
						style={fillA}
						d="M20.15,23.43H6.86a2.14,2.14,0,0,1-2.14-2.14V2.71A2.14,2.14,0,0,1,6.86.57H16.8L22.29,6V21.29A2.14,2.14,0,0,1,20.15,23.43Z"
					/>
					<path id="Shape_Copy_137" data-name="Shape Copy 137" style={fillB} d="M21.54,6.6H16.32V1.43Z" />
					<rect id="Rectangle_5_Copy_110" data-name="Rectangle 5 Copy 110" style={fillC} x="1.71" y="4.01" width="11.82" height="6.02" rx="2.14" />
					<g id="PNG" className="cls-4">
						<g className="icon-text">
							<path d="M3.67,5.7H4.78a.83.83,0,1,1,0,1.66H4.2v.79H3.67Zm1,1.17c.26,0,.44-.13.44-.34S5,6.18,4.72,6.18H4.2v.69Z" />
							<path d="M6.05,5.7h.52L7.75,7.29V5.7h.53V8.15H7.75L6.57,6.57V8.15H6.05Z" />
							<path
								d="M9.91,6.78H11v1a1.46,1.46,0,0,1-1,.41A1.31,1.31,0,0,1,8.69,6.93,1.31,1.31,0,0,1,10,5.66a1.41,1.41,0,0,1,1,.41l-.39.34A.81.81,0,0,0,10,6.14a.79.79,0,0,0,0,1.58.84.84,0,0,0,.48-.16V7.19H9.91Z"
							/>
						</g>
					</g>
					<path
						id="_copy_4"
						data-name=" copy 4"
						style={fillB}
						d="M16.53,20.34h-6a.82.82,0,0,1-.6-.25.83.83,0,0,1-.26-.61v-6a.84.84,0,0,1,.25-.6.79.79,0,0,1,.61-.25h6a.81.81,0,0,1,.6.24.83.83,0,0,1,.26.61v6a.87.87,0,0,1-.86.86ZM12,17.11h0l-1.5,1.94h6L14.6,16.47l-1.5,1.94L12,17.11Z"
					/>
				</g>
			</svg>
		);
	}
}
export default PngIcon;
