import React, { Component } from "react";
import "./Checkbox.scss";

class Checkbox extends Component {

	constructor(props) {
		super(props);
		this.onClick = this.onClick.bind(this);
	}

	onClick(e) {

		if (this.props.onChange) {
			this.props.onChange({
				currentTarget: {
					name: this.props.name,
					checked: !this.props.checked,
					value: this.props.value,
					type: "checkbox"
				}
			});
		}
	}

	render() {
		const { label, checked } = this.props;
		return (
			<div className="checkbox-field" onClick={this.onClick}><i className="material-icons">{checked ? "check_box" : "check_box_outline_blank"}</i>{label}</div>
		);
	}
}

export default Checkbox;
