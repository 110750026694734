import React, { Component } from "react";
import "./OmniSearch.scss";
import { withThreeSkyeGlobal } from "@threeskye/global";
import { hotkeys } from 'react-keyboard-shortcuts';
import { withRouter } from 'react-router-dom';
import withRouteChange from "@threeskye/route-change";
import { getAccountsList, getClientsList } from "../../utils/Utils";
import LoadingIcon from "@threeskye/loading-icon";
import { debounce } from "throttle-debounce";
import NoResultsMessage from "../Shared/NoResultsMessage";

class OmniSearch extends Component {
	constructor(props) {
		super(props);
		this.state = {
			searchText: this.props.searchText,
			searchResults: [],
			highlightedCategory: 0,
			highlightedResult: -1,
			loading: false
		};

		this.searchTextUpdated = this.searchTextUpdated.bind(this);
		this.doSearch = debounce(300, this.doSearch.bind(this));

		//Ensure search results come back in the right order
		this.searchCounter = 0;
		this.displayingSearch = -1;
		this.applyResults = this.applyResults.bind(this);
	}

	hot_keys = {
		'esc': {
			priority: 1,
			handler: e=>this.props.storage.put("crm.showSearch", false)
		},
		'down': {
			priority: 1,
			handler: this.selectionMoveDown.bind(this)
		},
		'up': {
			priority: 1,
			handler: this.selectionMoveUp.bind(this)
		},
		'enter': {
			priority: 1,
			handler: this.selectSelection.bind(this)
		},
		'backspace': {
			priority: 1,
			handler: this.backspaceCheck.bind(this)
		}
	}

	componentDidMount() {
		this.searchInput.focus();
		this.localCache = {};
		this.localPriority = {};
		// const self = this;
		// this.props.storage.put("omnisearch.cache", ()=>{
		// 	return {
		// 		clear: ()=>self.localCache = {},
		// 		get: ()=>self.localCache
		// 	}
		// });
		
		if (this.state.searchText.length > 0) {
			this.doSearch(this.state.searchText);
		}
	}

	searchTextUpdated(e) {
		let theText = e.target.value;
		this.setState({searchText: theText});
		if (theText.length > 0) {
			this.doSearch(theText);
		}
	}

	doSearch(text) {
		this.setState({loading: true});
		let values = this.localCache[text];
		const searchCounter = this.searchCounter++;
		this.localPriority[text] = searchCounter;
		if (values) {
			values.then(this.applyResults);
		} else {
			if (this.props.useRemoteSearch) {
				this.localCache[text] = this.props.remote.get("/modules/crm/search/" + searchCounter + "/" + text)
					.then(this.applyResults);
			} else {
				this.applyResults({results:[], searchText: text});
			}
		}
	}

	applyResults(resp) {
		const { results, searchText } = resp;
		let searchCounter;

		if (this.localPriority[searchText])
			searchCounter = this.localPriority[searchText];
		else
			searchCounter = resp.searchCounter;

		if (!searchCounter) {
			searchCounter = 0;
		}

		let localSearch = Promise.resolve("");

		//TODO move use local/remote to data implementation.
		if (this.props.useLocalSearch) {
			//Add in local search results
			const text = ".*" + searchText.toLowerCase().replace(/\s/g, ".*") + ".*";
			
			let accountSearch = getAccountsList(this.props.storage)
				.then(accounts=>{
					let regexp = new RegExp(text);
					let resultList = [];
					accounts.filter(account=>{
						return account.name?.toLowerCase().match(regexp) 
						|| account.number?.toLowerCase().match(regexp) 
						|| account.remoteSystemId?.match(regexp) 
						|| account.primary?.contactPhone?.number?.toString().match(regexp)
						|| account.primary?.tin?.match(regexp)
						;
					}).map(account=>{
						return {
							resultText: account.name,
							secondaryText: account.number,
							crmLink: "/accounts/" + account.number
						};
					}).forEach(account=>{
						resultList.push(account);
					});
					if (resultList.length > 0) {
						results.push({
							name: "Accounts",
							results: resultList
						});
					}
				});
			getClientsList(this.props.storage).then(clients=>console.log(clients));
			let clientSearch = getClientsList(this.props.storage)
				.then(clients=>{
					let regexp = new RegExp(text);
					let resultList = [];
					let clientResults = clients.filter(client=>{
						if (client.givenName) {
							return (client.givenName + " " + client.familyName).toLowerCase().match(regexp) 
								|| (client.preferredName + " " + client.familyName).toLowerCase().match(regexp) 
								|| (client.givenName + " " + client.middleNames + " " + client.familyName).toLowerCase().match(regexp) 
								|| (client.preferredName + " " + client.middleNames + " " + client.familyName).toLowerCase().match(regexp) 
								|| client.email?.toLowerCase().match(regexp) 
								|| client.contactPhone?.number?.toString().match(regexp)
								|| client.tin?.match(regexp)
								;
						} else {
							return client.name.toLowerCase().match(regexp);
						}
					}).map(client=>{
						return {
							resultText: client.name,
							secondaryText: client.email || "",
							crmLink: client.email ? ("/clients/" + client.email) : ("/nonpersonal/" + client.id)
						};
					});
					//Remove secondary text if it's not necessary
					clientResults.forEach(result=>{
						if (clientResults.filter(x=>x.resultText === result.resultText).length === 1) {
							result.secondaryText = "";
						}
					});
					clientResults.forEach(result=>{
						resultList.push(result);
					});
					if (resultList.length > 0) {
						results.push({
							name: "Clients",
							results: resultList
						});
					}
				});

			localSearch = Promise.all([accountSearch, clientSearch])
		}
		localSearch.then(()=>{
			if (this.displayingSearch < searchCounter) {
				this.displayingSearch = searchCounter;
				this.setState({searchResults: results, highlightedCategory: 0, highlightedResult: -1, loading: false});
			}
		});
		//Return unprocessed so it can be applied to applyResults again
		return resp;
	}

	selectionMoveUp(e) {
		let highlightedCategory = this.state.highlightedCategory;
		let highlightedResult = this.state.highlightedResult;

		if (highlightedResult > -1) {
			highlightedResult -= 1;
			if (highlightedResult < 0) {
				if (highlightedCategory > 0) {
					highlightedCategory -= 1;
					highlightedResult = this.state.searchResults[highlightedCategory].results.length-1;
				}
			}
			this.setState({highlightedCategory: highlightedCategory, highlightedResult: highlightedResult});
		}

		return false;
	}

	selectionMoveDown(e) {

		let highlightedCategory = this.state.highlightedCategory;
		let highlightedResult = this.state.highlightedResult;

		let maxResult = this.state.searchResults[highlightedCategory].results.length-1;
		if (highlightedResult === maxResult) {
			if (highlightedCategory === this.state.searchResults.length-1) {
				return;
			}
			++highlightedCategory;
			highlightedResult = 0;
		} else {
			++highlightedResult
		}

		this.setState({highlightedCategory: highlightedCategory, highlightedResult: highlightedResult});

		return false;
	}

	selectSelection() {
		let highlightedCategory = this.state.highlightedCategory;
		let highlightedResult = this.state.highlightedResult;

		let link;
		if (highlightedResult === -1) {
			//Single item case
			if (this.state.searchResults.length === 1 && this.state.searchResults[0].results.length === 1) {
				link = this.state.searchResults[0].results[0].crmLink;
			} else {
				return false;
			}
		} else {
			link = this.state.searchResults[highlightedCategory].results[highlightedResult].crmLink;
		}
		this.props.changeRoute(link);
		this.props.storage.put("crm.showSearch", false);
	}

	backspaceCheck() {
		if (this.state.searchText.length > 0) {
			return true;
		}

		this.props.storage.put("crm.showSearch", false);
		return false;
	}
	render() {
		const i = this.props.i18n.get;
		return (
			<div id="os-overlay">
				<div className="os-container">
					<div className="os-main-container">
						<div className="os-header-container">
							<div className="os-header-text">{i("SearchPlaceholder")}</div>
							<div className="os-close-container flex-center"	onClick={()=>this.props.storage.put("crm.showSearch", false)}>
								{i("Close")}
								<i className="material-icons">close</i>
							</div>
						</div>
						<div className="os-input-container">
							<i className="material-icons">search</i>
							<input
								type="text"
								id="os-input"
								name="omni-search"
								placeholder={i("OmniSearchPlaceholder")}
								value={this.state.searchText}
								onChange={this.searchTextUpdated}
								ref={input=>this.searchInput = input}
							/>
							{this.state.loading && <LoadingIcon centered size={35} />}
						</div>
						<div className="os-filter-container">
							{/* <i className="material-icons">filter_list</i> */}
							<ul className="os-filter-list">
								<li className="os-filter-item">
									jsmith@gmail.com <i className="material-icons">close</i>
								</li>
								<li className="os-filter-item">
									Documents <i className="material-icons">close</i>
								</li>
							</ul>
						</div>
					</div>
					<div className="os-results-container">
						{this.state.searchResults.length === 0 ? (
							<NoResultsMessage />
						) : (
							this.state.searchResults.map((category, index)=>(
								<div key={index} className="os-result-section">
									<div className="os-section-header"> {i(category.name)}</div>
									<ul className="os-results-list">
										{ category.results.map((value, index2)=>{
											let className = "os-result-item";
											if (this.state.highlightedCategory === index && this.state.highlightedResult === index2) {
												className += " os-result-selected";
											}
											return (
											<li key={index2} className={className} onClick={()=>{this.props.changeRoute(value.crmLink);this.props.storage.put("crm.showSearch", false);}}>
												{value.resultText}&nbsp;
												{ value.secondaryText ? <span className="os-result-tag">{value.secondaryText}</span> : ""}
											</li>
										)})}
									</ul>
							</div>))	
						)}
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(withThreeSkyeGlobal(withRouteChange(hotkeys(OmniSearch))));
