import React from 'react';
import FlexWrapper from '../../Shared/FlexWrapper';
import "./ExtensionEmptyMessage.scss";

const ExtensionEmptyMessage = ({icon, header, message, className, iconColor, clickableMessage, onMessageClick}) => {
	return (
		<FlexWrapper
			gap="s"
			direction="column"
			fullWidth
			className={`extension-empty-state-message${clickableMessage ? " clickable-message" : ""}${className ? " " + className : ""}`}
		>
			{icon && (
				<i className="material-icons" style={{ color: iconColor }}>
					{icon}
				</i>
			)}
			{header && <h4 className="mb-0">{header}</h4>}
			{(message || clickableMessage) && <p onClick={clickableMessage && onMessageClick}>{message || clickableMessage}</p>}
		</FlexWrapper>
	);
}

export default ExtensionEmptyMessage;