import React, { useState, useEffect, useRef } from 'react';
import Tooltip from '../Popups/Tooltip';
import { Button as EditorButton } from '../Shared/Slate/components';
import { withI18n, withStorage, withRemote } from '@threeskye/global'
import { renderOrder } from '../../utils/EventUtils';
import "./SlateAttachmentButton.scss"

const Icon = ({ children, symbolIcon, className }) => {
    return <i className={`material-icons${symbolIcon ? " material-symbols-outlined" : ""} ${className ? className : ""}`} >{children}</i>
}

const SlateAddOrderOrAssetButton = ({ i18n, storage, remote, updateOrdersToBeLinked, ordersTobeLinked, customIcon, buttonClassName, iconClassName, disabled, size, disabledMessage, accountId }) => {
    const i = i18n.get()
    const optionsListRef = useRef(null);

    const [searchbarOpen, setSearchbarOpen] = useState(false)
    const [searchValue, setSearchValue] = useState("");
    // const [assets, setAssets] = useState([])
    const [matchingOrders, setMatchingOrders] = useState([])
    const [selectedOptionIndex, setSelectedOptionIndex] = useState(-1);
    const [popupIsVisible, setPopupIsVisible] = useState(null)

    // useEffect(() => {
    //     storage.getOrFetch("/modules/crm/classification-scheme/Hobson").then((fetchedAssets) => {
    //         const anotherListOfAssets = fetchedAssets.unclassified.concat(
    //             fetchedAssets.classes.flatMap(classObj => classObj.assets)
    //         )
    //         setAssets(anotherListOfAssets)
    //     });
    // }, [])

    useEffect(() => {
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, [optionsListRef]);

    useEffect(() => {
        if (optionsListRef.current && selectedOptionIndex > -1 && optionsListRef.current.children && optionsListRef.current.children[selectedOptionIndex]) {
            optionsListRef.current.children[selectedOptionIndex].scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
                inline: 'nearest'
            });
        }
    }, [selectedOptionIndex]);


    useEffect(() => {
        if (searchValue === "") {
            setMatchingOrders([])
        }
    }, [searchValue])


    function handleClickOutside(event) {
        const element = event.target
        if ((element && element.className !== "cholula") && (element && element.className !== "tag-group")) {
            setSearchValue(false)
            setMatchingOrders([])
            setSearchValue("")
            setSearchbarOpen(false)
        }
    };

    function handleKeyDown(event) {
        if (event.key === 'ArrowDown' && selectedOptionIndex < matchingOrders.length - 1) {
            setSelectedOptionIndex(selectedOptionIndex + 1)
        } else if (event.key === 'ArrowUp' && selectedOptionIndex > -1) {
            setSelectedOptionIndex(selectedOptionIndex - 1)
        } else if (event.key === 'Enter' && selectedOptionIndex > -1) {
            handleOptionClick(selectedOptionIndex)
        }
    };

    function handleInputChange(e) {
        const { value } = e.target;
        setSearchValue(value);

        let base = "/modules/crm/provider/provider-historic-orders?x"
        if (value && value.length > 0) {
            base += `&search=${value}`

            const ordersToBeLinkedIds = ordersTobeLinked && ordersTobeLinked.length > 0 && ordersTobeLinked.map((ord) => {
                return ord.id || ord.orderId
            })

            const historicOrders = remote.get(base).then((fetchedHistoricOrders) => {
                return fetchedHistoricOrders.filter(o => (o.status !== "EXPIRED") && (!ordersToBeLinkedIds || ordersToBeLinkedIds && (!ordersToBeLinkedIds.includes(o.id))) && o.account.id === accountId && o.adviceEventId === null)
            })

            Promise.resolve(historicOrders).then((resp) => {
                console.log("historicOrders", resp)
                if (resp.length > 0) {
                    setMatchingOrders(resp)
                } else setMatchingOrders([])
            })
        }
    }


    function openSearchBar() {
        setSearchbarOpen(true)
        // setToggleTooltip(true)
    }


    function handleOptionClick(index) {
        const option = matchingOrders[index];
        const unlinkedOrder = ordersTobeLinked && ordersTobeLinked.length > 0 && ordersTobeLinked.filter(o => o.adviceEventId === null)
        // console.log("ordersTobeLinked", ordersTobeLinked)

        if (unlinkedOrder) {
            // console.log("#1 unlinkedOrder", unlinkedOrder)
            updateOrdersToBeLinked([...unlinkedOrder, option])
            setSearchbarOpen(false)
            setSearchValue("");
            setMatchingOrders([])
        } else {
            // console.log("#2")
            updateOrdersToBeLinked([option])
            setSearchValue("")
            setMatchingOrders([])
        }
        setSelectedOptionIndex(index)
        setPopupIsVisible(false)
        setTimeout(() => setPopupIsVisible(null), 50)
    };

    function handleOptionHover(index) {
        setSelectedOptionIndex(index)
    };

    function handleKeyDown(event) {
        if (event.key === 'ArrowDown' && selectedOptionIndex < matchingOrders.length - 1) {
            setSelectedOptionIndex(selectedOptionIndex + 1)
        } else if (event.key === 'ArrowUp' && selectedOptionIndex > -1) {
            setSelectedOptionIndex(selectedOptionIndex - 1)
        } else if (event.key === 'Enter' && selectedOptionIndex > -1) {
            handleOptionClick(selectedOptionIndex)
        }
    };

    return (
        <Tooltip
            toggleTooltip={!disabled}
            placement={"bottom"}
            className="sab-tooltip-wrapper"
            offsetX={searchbarOpen ? -30 : 0}
            label={disabled ? <div className="tag-group" onClick={disabled ? null : openSearchBar}>{disabledMessage}</div> : searchbarOpen ?
                <div className="sab-options-container" style={{ width: 377 }}>
                    <input
                        type="text"
                        style={{ backgroundColor: "#fff", margin: "0px" }}
                        value={searchValue}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyDown}
                        autoFocus
                        placeholder="Search Account/Ticker/Company"
                        className='cholula'
                    />

                    {matchingOrders && matchingOrders.length > 0 && (
                        <ul className='matches' style={{ marginTop: "3px", width: size && size === "small-medium" ? 530 : 980, transform: !size ? "translateX(293px)" : "" }} ref={optionsListRef} tabIndex='0'>
                            {matchingOrders.map((option, index) => (
                                <li
                                    key={option.id}
                                    onClick={() => handleOptionClick(index)}
                                    onMouseEnter={() => handleOptionHover(index)}
                                    onKeyDown={handleKeyDown}
                                    className={`individual-match order ${selectedOptionIndex === index ? "selected" : ""}`}
                                >
                                    {renderOrder(option, index, null, size)}
                                </li>
                            ))}
                        </ul>
                    )}
                    {matchingOrders && matchingOrders.length === 0 && searchValue !== "" && (
                        <ul className='matches' style={{ marginTop: "3px", width: "inherit" }} ref={optionsListRef} tabIndex='0'>
                            <li className={`not-found`}>No options</li>
                        </ul>
                    )}
                </div> :

                <>
                    {/* <div className="tag-group" onClick={openSearchBar}>Tag Asset</div> */}
                    <div className="tag-group" onClick={openSearchBar}>Tag Order</div>
                </>
            }
            popupElementClass="sab-options-container"
            popupOuterClass=""
            stopClickEfect
            popupElementStyle={{ backgroundColor: "#424E7B", display: "flex", flexDirection: "column", borderRadius: "8px", padding: "0px" }}
            closeOnOutsideClick
            isVisible={popupIsVisible}
        >
            <EditorButton className={buttonClassName}>
                <Icon className={iconClassName} >{customIcon ? customIcon : "add"}</Icon>
            </EditorButton>
        </Tooltip>
    );
}

export default withStorage(withRemote(withI18n(SlateAddOrderOrAssetButton)));