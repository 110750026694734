import React, { Component } from 'react';
import { withI18n, withRemote, withStorage } from '@threeskye/global';
import Minimiseds from './Minimiseds';
import Sidebar from '../components/Sidebar/Sidebar';
import SidebarList from '../components/Sidebar/SidebarList';
import SidebarItem from '../components/Sidebar/SidebarItem';
import MinimisedTab from '../components/Sidebar/MinimisedTab';
import { ModalTypes } from '../utils/ModalUtils';
import {eventTypes} from '../utils/EventUtils'

class Unfinisheds extends Component {

    constructor(props) {
        super(props);
        this.state = {
            sidebarVisible: false,
            sidebarPromptVisible: false
        };
        this.initialise = this.initialise.bind(this);
        this.onItemClick = this.onItemClick.bind(this);
        this.onItemDelete = this.onItemDelete.bind(this);
        this.deleteAll = this.deleteAll.bind(this);
        this.updateDraftList = this.updateDraftList.bind(this);
        this.getDraftList = this.getDraftList.bind(this);
        this.initialise(this.props);
    }

    initialise(props) {
        this.updateDraftList();
        // this.props.storage.getOrFetch("/modules/crm/provider/draft-events").then(this.getDraftList);
        this.props.storage.watch("/modules/crm/provider/draft-events", this.getDraftList);
	}
    
    /**
     * Get event drafts from the backend not from the crm.modals
    */
    updateDraftList() {
        this.props.remote.get("/modules/crm/provider/draft-events").then(results => {
            this.props.storage.put("/modules/crm/provider/draft-events", results);
            // this.getDraftList(results);
        });
    }

    componentWillUnmount() {
        this.props.storage.unwatch("/modules/crm/provider/draft-events");
    }
    
    /**
     * @author tommy
     * @param {*} results
     * get the list of drafts and convert into modals
     * then put them into the modals.inactive[] 
     */
    getDraftList(results) {
        this.props.modals.clearInactive();
        if (results && results.length > 0) {
            results.map((draft, index) => {
                const draftWithFixedEntities = {...draft, accounts: draft.accounts.map(e => e.entityId), clients: draft.clients.map(e => e.entityId), nonPersonClients: draft.nonPersonClients.map(e => e.entityId)}
                const modal = this.convertDraftIntoModal(draftWithFixedEntities)
                this.props.modals.addInactive(ModalTypes.CreateEvent, modal);
            });
        } else {
            this.setState({sidebarVisible: false});
        }
    }
        
    /**
     * @author tommy
     * TODO: Convert the event from backend into a modal type in the frontend OR don't use modals.inactive at all
     */
    convertDraftIntoModal(draft) {
        // const accounts = [], clients = [], 
        const attachments = [];
        // draft.accounts?.forEach(account => {
        //     accounts.push({manual:false, automatic:0, autoFlowOn: 0, data:{id:account}})
        // });
        // draft.clients?.forEach(client => {
        //     clients.push({manual:false, automatic:0, autoFlowOn: 0, data:{id:client}})
        // });
        // draft.nonPersonClients?.forEach(client => {
        //     clients.push({manual:false, automatic:0, autoFlowOn: 0, data:{id:client}})
        // });
        draft.attachments.forEach(attachment => {
            const {filename, s3key} = attachment;
            attachments.push({name:filename, s3Key:s3key, uploadComplete:true, progress:100, status:''});
        });

		let heading = draft.heading;
		if (!heading) {
			const body = JSON.parse(draft.content)[0];
			if (body && body.type && (body.type === 'paragraph' || body.type === 'heading-one' || body.type === 'heading-two')) {
				heading = body.children[0].text;
			} else {
				heading = '(no preview)';
			}
		}
 
        const modal = {
            // requiresInit: true, 
            initType: "draft", 
            // eventType: eventTypes[draft.type.id - 1], 
            type: {
                minimisedComponent: "MinimisedEvent" 
            }, 
            preload: { 
                id: draft.id,
                created: new Date(),
                date: draft.dateString,
                time: (draft.dateString&&draft.hasTime) || (!draft.dateString&&draft.timeString) ? draft.timeString : null,
                location: {
                    text: draft.location,
                    source: "draft"
                },
                text: JSON.parse(draft.content),
                header: draft.heading,
                sendAsEmail: draft.sendAsEmail,
                draftClients: draft.clients,
                draftNonPersonClients: draft.nonPersonClients,
                draftAccounts: draft.accounts,
                attachments: attachments,
                adviceCheckConfirmed: draft.advice,
				emails: draft.emails,
                /**
                 * Event type conversion.
                 * phonecall[1] ==> phone-call[0]
                 * meeting[2] ==> meeting[1]
                 * Note[3] ==> file-note[2]
                 * 
                 */
                eventType: draft.emailTemplate ? eventTypes[7] : eventTypes[draft.type.id - 1],
                emailTemplate: draft.emailTemplate,
            }, 
            /**
             * for sidebar
             */
            state: { 
                id: draft.id, 
                eventType: draft.emailTemplate ? eventTypes[7] : eventTypes[draft.type.id - 1],
                emailTemplate: draft.emailTemplate,
                header: heading,
                date: draft.dateStringForSidebar,
                time: (draft.dateString&&draft.hasTime) || (!draft.dateString&&draft.timeString) ? draft.timeString : null
            }
        };
        return modal;
    }

    /**
     * This is called whenever any change happens to props 
     * @param {*} props 
     */
    componentWillReceiveProps(props) {
        /**
         * update the list of the event drafts
         */
        // this.updateDraftList();
        if (props.modals.active.length !== this.props.modals.active.length) {
            //Trigger a redraw
            this.setState({sidebarVisible: this.state.sidebarVisible});
        }
    }

    onItemClick(index) {
        // this.props.modals.inactive[index].updateState({created:new Date()});
        this.props.modals.activate(index);
        this.setState({sidebarVisible: false});
    }

    onItemDelete(index, event) {
        event.stopPropagation();
        /**
         * API call to remove the draft
         */
        const eventId = this.props.modals.inactive[index].data.state.id;
        this.props.remote.delete("/modules/crm/provider/draft-events/"+eventId).then(()=>{
            this.updateDraftList();
        });
    }

    deleteAll() {
        const numItems = this.props.modals.inactive.length;
        const eventIds = []
        for(let i=0; i<numItems; i++){
            eventIds.push(this.props.modals.inactive[i].data.state.id);
        }
        /**
         * API call to remove all the draft
         */
        this.props.remote.post("/modules/crm/provider/draft-events", eventIds).then(()=>{
            this.updateDraftList();
        });
    }

    render() {
        //Sections are removed
        // let nothing = this.props.modals.inactive.length === 0;
        // let data = [];

        // if (!nothing) {
        //     let sections = {};

        //     let inactive = this.props.modals.inactive;
        //     for (let i=0; i<inactive.length; i++) {
        //         let modal = inactive[i];
        //         if (modal.type.heading) {
        //             let list = sections[modal.type.heading];
        //             if (!list) {
        //                 list = [];
        //                 sections[modal.type.heading] = list;
        //             }
        //             list.push({index: i, modal});
        //         }
        //     }

        //     Object.keys(sections).forEach(header=>{
        //         let section = sections[header];
        //         data.push({
        //             header: "Events",
        //             data: section
        //         });
        //     });
        // }

        let i = this.props.i18n.get;

        let visible = this.state.sidebarVisible && this.props.modals.active.length === 0;
        return <React.Fragment>
            <Sidebar 
                visible={visible}
                headerText={i("UnfinishedTasks")} 
                headerClassNames="mb-3"
                footerButton
                footerButtonIcon="delete_sweep"
                footerButtonText="Delete All"
                footerButtonClick={this.deleteAll}
                promptVisible={this.state.sidebarPromptVisible}
                promptText="Delete 3 unfinished events?"
                onOverlayClick={()=>this.setState({sidebarVisible: false})}
                onClose={()=>this.setState({sidebarVisible: false})}
            >
                <SidebarList sectionHeader={null}>
                    {
                        this.props.modals.inactive.map((item,idx)=>{
                            return <SidebarItem key={idx} onClick={e=>this.onItemClick(idx)}>
                                <Minimiseds component={item.type.minimisedComponent} modal={item} onDelete={e=>this.onItemDelete(idx, e)} />
                            </SidebarItem>
                        })
                    }
                </SidebarList>
            </Sidebar>
            { !this.state.sidebarVisible && this.props.modals.inactive.length > 0 ? <MinimisedTab onClick={e=>this.setState({sidebarVisible: true})}>{this.props.modals.inactive.length} {i("UnfinishedTasks")}</MinimisedTab> : "" }
        </React.Fragment>
    }
}

export default withStorage(withRemote(withI18n(Unfinisheds)));