import React, { Component } from "react";
import "./ValidationInput.scss";
import "./ErrorMessage.scss";
// import { getClassName } from "../../../utils/Utils";

const getClassName = (classNameItems) => {
	return classNameItems.reduce((className, item) => (item.className && (!item.condition || item.condition()) ? (className ? `${className} ${item.className}` : item.className) : className), "");
}
class CRMErrorMessage extends Component {
	state = { shouldShow: false };
	warningMessageClassNameItems = [{ className: `input-validation-warning-message${this.props.noMargin ? " mt-0" : ""}` }, { className: "parent-error", condition: () => this.props.children.length > 1 }];
	

	render() {
		let { isValid, children, message, shouldShow, onBlur } = this.props;
		shouldShow = shouldShow || this.state.shouldShow;
		if (isValid || !shouldShow) {
			return (
				<div className="crm-error-message" tabIndex="-1" onBlur={onBlur || (() => this.setState({ shouldShow: true }))}>
					{children}
				</div>
			);
		} else {
			return (
				<>
					<div className="crm-error-message is-invalid">{children}</div>
					{message && <div className={getClassName(this.warningMessageClassNameItems)}>
						<div className="input-validation-warning-icon">
							<i className="material-icons validation-error">error</i>
						</div>
						<p className="input-validation-error-message">{message}</p>
					</div>}
				</>
			);
		}
	}
}

export default CRMErrorMessage;
