export function impersonate(user, remote, storage, history) {

    remote.get("/users/impersonate/" + user.id)
        .then(result=>{
            let sessionStorage = window.sessionStorage;
            let token = result.data;
            let realToken = sessionStorage.getItem("3skye.auth.token");
            sessionStorage.setItem("3skye.auth.super.token", realToken);
			sessionStorage.setItem("3skye.auth.token", token);
			sessionStorage.setItem("3skye.returnPath",window.location.pathname);
            storage.clear();
            window.location.pathname = "/";
        });
}

export function unimpersonate(storage) {
    let token = sessionStorage.getItem("3skye.auth.super.token");
    sessionStorage.removeItem("3skye.auth.super.token");
	sessionStorage.setItem("3skye.auth.token", token);
	let returnPath = sessionStorage.getItem("3skye.returnPath");
	sessionStorage.removeItem("3skye.returnPath");
    storage.clear();
    
    window.location.pathname = returnPath||"/";
}