import React, { useState, useEffect } from 'react';
import CreatableClientOrAccountTokenField from '../CreatableClientOrAccountTokenField'
import AddableFieldWrapper from '../DetailsPage/Fields/AddableFieldWrapper';
import "./EmailTaggingField.scss";

const noOptionsMessage = (inputValue, onClick, values) => {
	return <div className="create-suggestion" onClick={() => onClick(inputValue, values)}>
		Use <span style={{fontWeight: 500}}>"{inputValue.inputValue}"</span>
	</div>;
}

const EmailTaggingField = (props) => {
	const [bccOn, setBccOn] = useState(true);
	const {toClientList, ccClientList, bccClientList, toEmailList, ccEmailList, bccEmailList, toAccountList, ccAccountList, bccAccountList, onToAdded, onCcAdded, onBccAdded, ...rest} = props;

	return (
		<div className="etf-outer-wrapper">
			<AddableFieldWrapper noLabel>
				{/* To: tagging field */}
				<CreatableClientOrAccountTokenField 
					{...rest}
					clientList={toClientList || []} 
					emailList={toEmailList || []} 
					accountList={toAccountList || []} 
					onAccountUpdate={(acc, removed) => onToAdded('account', acc, removed)} 
					onClientUpdate={(clnt, removed) => onToAdded('client', clnt, removed)} 
					onEmailUpdate={(email, removed) => {onToAdded('email', email, removed)}} 
					placeholder="To" noOptionsMessage={noOptionsMessage} 
					isEmailTemplate={props.isEmailTemplate}/>
				<div className={`cc-wrapper${bccOn ? " bcc-active" : ""}`}>
					<CreatableClientOrAccountTokenField 
						{...rest}
						clientList={ccClientList  || []}
						emailList={ccEmailList  || []}  
						accountList={ccAccountList  || []} 
						onAccountUpdate={(acc, removed) => onCcAdded('account', acc, removed)} 
						onClientUpdate={(clnt, removed) => onCcAdded('client', clnt, removed)} 
						onEmailUpdate={(email, removed) => onCcAdded('email', email, removed)} 
						placeholder="Cc" 
						noOptionsMessage={noOptionsMessage} 
						isEmailTemplate={props.isEmailTemplate}/>
					{!bccOn && <span className="bcc-button" onClick={() => setBccOn(true)}>Bcc</span>}
				</div>
				{/* Colin, could you look at stopping AddableFieldWrapper from rendering the 'single-field-wrapper' before it's meant to? It's causing another divider to appear unnecessarily */}
				{bccOn && <CreatableClientOrAccountTokenField 
					{...rest}
					clientList={bccClientList  || []}					
					emailList={bccEmailList  || []}  
					accountList={bccAccountList  || []} 
					onAccountUpdate={(acc, removed) => onBccAdded('account', acc, removed)} 
					onClientUpdate={(clnt, removed) => onBccAdded('client', clnt, removed)} 
					onEmailUpdate={(email, removed) => onBccAdded('email', email, removed)} 
					placeholder="Bcc" 
					noOptionsMessage={noOptionsMessage} 
					isEmailTemplate={props.isEmailTemplate} />}
			</AddableFieldWrapper>
		</div>
	);
}

export default React.memo(EmailTaggingField);