import React from "react";
import "./App.scss";
import ThreeSkyeLogin from "./components/Login/ThreeSkyeLogin";
import ViewSelector from "./ViewSelector";
import ImpersonationWrapper from "./crm/ImpersonationWrapper";

const App = ()=> {
	return <ThreeSkyeLogin>
		{window.sessionStorage.getItem("3skye.auth.super.token") !== null ? <ImpersonationWrapper /> : <ViewSelector />}
	</ThreeSkyeLogin>

}

export default App;
