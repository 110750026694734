import React from 'react';
import "./NotificationMiniList.scss";

export const MiniNotification = ({ children, onDelete, data, onClick, pointer} ) => {	
	return (
		<div className="mini-notification-wrapper" style={{cursor: pointer ? "pointer" : "default"}}>
			<div className="mini-notification-inner" onClick={() =>  pointer ? onClick(data) : () => null}>
				{children}
			</div>
			{/* action icons */}
			<div className="mini-notification-actions">
				<i className="material-icons delete-icon" onClick={(e) => onDelete(e, data)}>
					delete_forever
				</i>
			</div>
		</div>
	);
};

const NotificationMiniList = ({children}) => {
	return (
		<div className="notification-mini-list">
			{children.map((child, idx) =>
				<div key={idx} className="nml-item-wrapper">
					{child}
				</div>
			)}
		</div>
   );
}

export default (NotificationMiniList);