import React from 'react';
import Tooltip from '../Popups/Tooltip';
import PropTypes from 'prop-types';

const ChangeRequestIcon = ({state, cr, nested}) => {
	return (
		<Tooltip disabled={!cr} label={cr} offsetY={-2}>
			<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
				<g id="Group_5878" data-name="Group 5878" transform="translate(17577 5793)">
					<path
						className={`cr-icon-path ` + state}
						id="Path_1906"
						data-name="Path 1906"
						d="M9.2-48.291a5.815,5.815,0,0,0,4.177-1.8l-1.674-1.5a3.348,3.348,0,0,1-2.5,1.2,3.459,3.459,0,0,1-3.332-3.473A3.456,3.456,0,0,1,9.2-57.319a3.348,3.348,0,0,1,2.5,1.2l1.674-1.5a5.753,5.753,0,0,0-4.177-1.8,5.712,5.712,0,0,0-5.711,5.554A5.726,5.726,0,0,0,9.2-48.291Zm14.691-.188-2.644-3.755A3.5,3.5,0,0,0,23.683-55.6c0-2.128-1.721-3.63-4.052-3.63H14.8v10.748h2.3v-3.489H18.8l2.456,3.489ZM17.1-57.115h2.284c1.142,0,1.924.595,1.924,1.518s-.782,1.518-1.924,1.518H17.1Z"
						transform="translate(-17578.484 -5727.585)"
						fill={state === "approved" ? "#30C9AC" : state === "rejected" ?  "#F7A43D" : state === "feedback" ? "#0D8EFD" : state === "primary" ? "#0d8efd" : "grey"}
					/>
					<rect id="Rectangle_5695" data-name="Rectangle 5695" width="24" height="24" transform="translate(-17577 -5793)" fill="none" />
				</g>
			</svg>
		</Tooltip>
	);
	
}

ChangeRequestIcon.propTypes = {
	state: PropTypes.oneOf(['approved', 'rejected', `feedback`]),
	tooltip: PropTypes.string
}

export default ChangeRequestIcon;