import React, { Component } from 'react';
import {ThreeSkyeWrapper} from "@threeskye/global";
import AuthChecker from './AuthChecker';
import { CookiesProvider } from 'react-cookie';
import CookieChecker from './CookieChecker';

import /*theme*/ './ThreeSkye.scss';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import CredentialTransfer from './CredentialTransfer';
import FedCredentialTransfer from './FedCredentialTransfer';

class ThreeSkyeLogin extends Component {

  render() {

    return (
      <CookiesProvider>
      <ThreeSkyeWrapper>
        {/* For now we're ditching the theme idea <Theme /> */}
		{/* Redirect has to go here so it's inside ThreeSkyeWrapper but outside Auth */}
		<BrowserRouter>
			<Switch>
				<Route path="/_cx/:token/*" render={()=><CredentialTransfer />} />
				<Route path="/_fed/:token/*" render={()=><FedCredentialTransfer />} />
				<Route render={()=><>
					<AuthChecker children={this.props.children} />
					<CookieChecker/>
				</>} />
			</Switch>
		</BrowserRouter>
      </ThreeSkyeWrapper>
    </CookiesProvider>
    );
  }
}

export default ThreeSkyeLogin;
