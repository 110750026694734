import React, { Component, Suspense } from 'react';
import { Trigger } from '@threeskye/tagged-text-area';
import { isoDate } from './Utils';
import { convertToRaw, convertFromRaw, Modifier } from 'draft-js';
import EventDetailUpdateItem from "../components/Events/EventDetailUpdateItem";
import CompanyImage from '../components/Portfolio/CompanyImage'
import { isLooselyEqual } from "./Utils";
import _ from "lodash";
import FlexWrapper from '../components/Shared/FlexWrapper';
import LoadingIcon from "@threeskye/loading-icon"
import BuySellToken from '../components/Shared/BuySellToken'
import { parseISO, format } from 'date-fns';
import ClientAccountLink from '../components/ClientAccount/ClientAccountLink'
import Tooltip from '../components/Popups/Tooltip';


export function headerFromText(rawContent) {
    if (!rawContent.blocks || rawContent.blocks.length === 0 || rawContent.blocks[0].text.length === 0)
        return null;
    let firstLine = rawContent.blocks[0].text;

    if (firstLine.length > 30)
        firstLine = firstLine.substring(0, 30) + "...";

    return firstLine;
}
//wants a simple string
export function getSummary(event) {
    var content = JSON.parse(event.content);
    if (!content.blocks
        || content.blocks === null
        || content.blocks.length === 0
        || content.blocks[0].text === event.heading
    )
        return "";
    return content.blocks[0].text
}
export function removeHeadingFromBody(event) {
    var content = JSON.parse(event.content);
    let blocks = content.blocks;
    if (!event.heading) {
        return content;
    }
    if (!blocks) {
        return event.content;
    }

    //check exact matches first
    if (blocks[0] && blocks[0].text === event.heading) {
        var shifted = content.blocks.shift(); //if the first block is exact, remove it
        if (content.blocks.length === 0) {
            shifted.text = "";
            content.blocks.unshift(shifted);
        }
        return content;
    }

    //we have a partial match, meaning a run-on
    //split the heading by spaces
    var heading = event.heading.split(" ");
    var subheading = "";
    for (var idx = 0; idx < heading.length - 1; idx++) {
        subheading = subheading.concat(heading[idx], " ");
    }

    //shrink our heading list
    heading = heading.pop();

    //check that it is a header from body situation 
    if (heading.endsWith("...")) {
        //use draft js to remove the text at the start
        let rawState = convertFromRaw(content);
        let rawSelect = rawState.getSelectionAfter();
        let offsetSelect = rawSelect.merge({ focusOffset: subheading.length });
        let removedState = Modifier.removeRange(rawState, offsetSelect);

        //the last step is to check for a "perfect" match of the final heading split
        let perfect = removedState.getBlockForKey(offsetSelect.getAnchorKey()).getText(); //full text
        //Check 
        if (perfect.indexOf(heading.slice(0, heading.length - 3).concat(" ")) === 0) {
            let fullSelect = rawSelect.merge({ focusOffset: heading.length - 3 });
            let fullRemovedState = Modifier.removeRange(rawState, fullSelect);
            return convertToRaw(fullRemovedState);
        }

        let completedContent = convertToRaw(removedState);

        return completedContent;
    }

    // Header is not from body
    return content;
}

//Note well that other parts of code rely on the order of the event Types array.  Please ensure modification of this order
//is flowed through to those places as well. 
export const eventTypes = [
    {
        name: "phone-call",
        type: "phone-call",
        location: false,
        datetime: true,
        clientaccount: true,
        text: "Phone-call",
        goodFor: "Permanent record of a phone call with a client.",
        adviceable: true,
        attachment: true,
        draftable: true,
        order: 6
    },
    {
        name: "meeting",
        type: "meeting",
        location: true,
        datetime: true,
        clientaccount: true,
        text: "Meeting",
        goodFor: "Permanent record of a meeting with a client.  If the meeting is in the future a notification will be issued",
        adviceable: true,
        attachment: true,
        draftable: true,
        order: 4
    },
    {
        name: "file-note",
        type: "file-note",
        location: false,
        datetime: false,
        clientaccount: true,
        text: "File Note",
        goodFor: "Permanent note on the client or account.  Used for advice.",
        adviceable: true,
        attachment: true,
        draftable: true,
        order: 3
    },
    {
        name: "workspace-note",
        type: "workspace-note",
        location: false,
        datetime: false,
        clientaccount: false,
        text: "Note",
        goodFor: "A personal note.  Will appear in your dashboard.  Good for todo lists.  Not saved permanently",
        adviceable: false,
        update: "/modules/crm/notes",
        labelClass: "first-of-section",
        order: 5
    },

    {
        name: "email",
        type: "email",
        location: false,
        datetime: false,
        clientaccount: false,
        text: "Email",
        goodFor: "Sending an email",
        adviceable: false,
        draftable: true,
        order: 1,
        attachment: true
    },
    {
        name: "reminder",
        type: "reminder",
        location: false,
        datetime: true,
        clientaccount: false,
        text: "Reminder",
        goodFor: "A personal reminder.  Will appear in your dashboard.  A notification will be issued.  Not saved permanently",
        adviceable: false,
        draftable: true,
        update: "/modules/crm/notes",
        order: 7
    },
    {
        name: "bulk-sharing",
        text: "Bulk Sharing",
        order: 8
    },
    {
        name: "email-template",
        type: "email",
        location: false,
        datetime: false,
        clientaccount: false,
        text: "Email Templates",
        goodFor: "Sending an templated email",
        adviceable: false,
        draftable: true,
        order: 2,
        attachment: true
    }
];

export function orderedEventTypes(string) {
    if (string && string === "topbarCreateButton") {
        const newEventTypeList = eventTypes.filter(eventType => eventType.name !== "email-template")
        return [...newEventTypeList].sort((a, b) => a.order - b.order)
    } else {
        return [...eventTypes].sort((a, b) => a.order - b.order);
    }
}

export function stateFromDTO(storage, dto) {

    /*
     * Undoing

    state.selectedAccounts.forEach(account=>{
        if (account.manual || account.automatic > 0) {
            accounts.push(account.data.id);
        }
    });

    state.selectedClients.forEach(client=>{
        if (client.manual || client.automatic > 0) {
            clients.push(client.data.id);
        }
    });

    let data = {
        type: state.eventType.name,
        date: state.date === null ? null : state.date.toISOString(),
        time: state.time,
        location: state.location,
        accounts,
        clients,
        content: state.text,
        heading: state.header,
        draft
    };

    */

    let state = {
        eventType: eventTypes.find(type => type.name === dto.type),
        helpText: "",
        created: new Date(),
        text: dto.text,
        header: dto.heading,
        clients: [],
        accounts: [],
        location: dto.location,
        date: null,
        time: dto.time,
        selectedAccounts: [],
        selectedClients: []
    };

    return state;
}
export function stateFromNote(note) {
    //deep clone our old content state
    let text = JSON.parse(note.content);
    let state = {
        created: new Date(),
        clients: [],
        accounts: [],
        location: null,
        date: null,
        time: null,
        selectedAccounts: [],
        selectedClients: [],
        eventType: eventTypes.find(type => type.name === "workspace-note"),
        helpText: "",
        text,
        header: note.title,
        attachments: [],
        sendAsEmail: false,
        errorMessage: null,
        locationFieldValid: true,
        dateTimeFieldValid: true,
        accClientFieldValid: true,
        noteFieldValid: true
    };
    return state;
}

export function renderAccount(account, icon, onClick) {
    const className = account.autoFlowOn > 0 ? " suggestion" : "";
    return <span className={`account-token-option ${className}`} key={account.data.name} onClick={onClick && onClick}>
        {icon ? <i className="material-icons account">account_balance</i> : null}
        <span className="token-selection-main">{account.data.name}</span>
        <span className="token-selection-sub">{account.data.number}</span>
    </span>
}

// Renders for email tagging fields

export function renderAccountEmail(account, icon) {
    const className = account.autoFlowOn > 0 ? " suggestion" : "";
    const accountEmail = account.data?.primary?.email || "-";
    return <span className={`account-token-option email-option ${className}`} key={account?.data?.name}>
        {icon ? <i className="material-icons account" style={{ flexShrink: 0 }}>account_balance</i> : null}
        <FlexWrapper gap="xs" direction="column" align="flex-start">
            <span className="token-selection-main">{account?.data?.name}</span>
            <span className="token-selection-sub">{accountEmail}</span>
        </FlexWrapper>
    </span>
}

export function renderClientEmail(client, icon) {
    const className = client.autoFlowOn > 0 ? "suggestion" : "";
    const clientEmail = client?.data?.email && client.data.email.includes("@") ? client.data.email : client?.data?.contactEmail ? client.data.contactEmail : "-";
    return <span className={`email-option ${className + client.data.type === "PERSON" ? " client-token-option" : " npe-token-option"}`} key={client.data.email}>
        {icon ? client.data.type === "PERSON" ? <i className="material-icons client">person</i> : <i className="material-icons client">business</i> : null}
        <FlexWrapper gap="xs" direction="column" align="flex-start">
            <span className="token-selection-main">{client.data.type === "PERSON" ? (client.data.preferredName || client.data.givenName) + " " + client.data.familyName : client.data.name}</span>
            <span className="token-selection-sub">{clientEmail}</span>
        </FlexWrapper>
    </span>;
}

// ========================================

export function accountAsText(account) {
    return account.data.name;
}

class TaggedSpan extends Component {
    render() {
        let data = this.props.contentState.getEntity(this.props.entityKey).getData();
        return <span className={data.type}>{data.info}</span>
    }
}
export function clientAsText(client) {
    let fullName = client.data.preferredName + " " + client.data.familyName;
    return fullName;
}
export function renderClient(client, icon) {
    // return <ClientAccountToken clientAccount={client.data} size={ClientAccountTokenSizes.SMALL} />
    const className = client.autoFlowOn > 0 ? "suggestion" : "";
    return <span className={className + client.data.type === "PERSON" ? " client-token-option" : " npe-token-option"} key={client.data.email}>
        {icon ? client.data.type === "PERSON" ? <i className="material-icons client">person</i> : <i className="material-icons client">business</i> : null}
        <span className="token-selection-main">{client.data.type === "PERSON" ? (client.data.preferredName || client.data.givenName) + " " + client.data.familyName : client.data.name}</span>
        {client.expandRequired ? <span className="token-selection-sub">{client.data.email}</span> : ""}
    </span>;
}
export function matchClients(str, searchCounter, remote) {
    const match = str.toLowerCase();

    if (str.length > 2) {
        return remote.get(`/modules/crm/conditional-access/clients/${searchCounter}/search?q=${escape(match)}`).then(clients => {
            return clients.results.map(client => { return { autoFlowOn: 0, automatic: 0, data: client, listObjectType: "client", manual: true, searchCounter: clients.searchCounter } });
        });
    } else {
        return [];
    }
}
export function matchAccounts(str, searchCounter, remote, requiresTemplate = false) {
    const match = str.toLowerCase();

    if (str.length > 2) {
        return remote.get(`/modules/crm/conditional-access/accounts/${searchCounter}/search?q=${escape(match)}&requireTemplate=${requiresTemplate}`).then(accounts => {
            return accounts.results.map(account => { return { autoFlowOn: 0, automatic: 0, data: account, listObjectType: "account", manual: true, searchCounter: accounts.searchCounter } });
        });
    } else {
        return [];
    }
}


export class ClientTrigger extends Component {

    constructor(props) {
        super(props);
        this.searchCounter = 0;
        this.search = this.search.bind(this);

    }
    search(str) {
        //FIXME - convert async call to synchronised return somehow??
        //		return [];
        const resolved = Promise.resolve(matchClients(str, this.searchCounter++, this.props.remote));
        return resolved;
    }
    clientData(client) {
        //Because the data will be saved with the text we don't want the full client details - just enough to 
        //ensure sufficient is provided for use and later identification.
        return {
            preferredName: client.data.preferredName,
            familyName: client.data.familyName,
            email: client.data.email,
            id: client.data.id
        }
    }
    render() {
        return <Trigger
            character="@"
            onSelect={this.props.onSelect}
            onRemove={this.props.onRemove}
            data={this.search}
            listDisplay={renderClient}
            asText={clientAsText}
            dataForRenderer={this.clientData}
            renderer={TaggedSpan}
        />
    }
}

export class AccountTrigger extends Component {
    constructor(props) {
        super(props);
        this.searchCounter = 0;
        this.search = this.search.bind(this);
    }
    search(str) {
        //		return [];
        return Promise.resolve(matchAccounts(str, this.searchCounter++, this.props.remote));
    }
    accountData(account) {
        //Because the data will be saved with the text we don't want the full client details - just enough to 
        //ensure sufficient is provided for use and later identification.
        return {
            name: account.data.name,
            number: account.data.number,
            id: account.data.id
        }
    }
    render() {
        return <Trigger
            character="#"
            onSelect={this.props.onSelect}
            onRemove={this.props.onRemove}
            data={this.search}
            listDisplay={renderAccount}
            asText={accountAsText}
            dataForRenderer={this.accountData}
            renderer={TaggedSpan}
        />
    }
}

export const deleteEvent = (eventId, remote, storage) => {
    remote.delete("/modules/crm/provider/draft-events/" + eventId)
        .then(resp => {
            if (resp.refresh) {
                resp.refresh.forEach(refresh => storage.refresh(refresh));
            }
        });
}

export const saveEvent = (state, remote, storage, draft, setEventId, displayToast, refreshList, linkOrderFunction, dontUpdateLinkedOrders) => {
    let accounts = [], clients = [], nonPersonClients = [];

    state.selectedAccounts.forEach(account => {
        if (account.manual || account.automatic > 0) {
            accounts.push(account.data.id);
        }
    });

    state.selectedClients.forEach(client => {
        if (client.manual || client.automatic > 0) {
            if (client.data.type !== "PERSON") {
                nonPersonClients.push(client.data.id);
            } else {
                clients.push(client.data.id);
            }
        }
    });


    let data = {
        id: state.id,
        workspaceNoteId: state.workspaceNoteId,
        createdDate: isoDate(state.created),
        type: state.eventType.type,
        date: state.date,
        time: state.time,
        location: state.location,
        accounts,
        clients,
        nonPersonClients,
        content: state.text,
        heading: state.header,
        draft,
        sendAsEmail: !draft && state.sendAsEmail,
        attachments: state.attachments.map(att => {
            let { name, s3Key, uploadComplete } = att;
            return {
                name, s3Key, uploadComplete
            };
        }),
        advice: state.adviceCheckConfirmed,
        emailDetails: {},
        emailTemplate: state.emailTemplate,
        linkedOrders: !dontUpdateLinkedOrders ? state.ordersToBeLinked : []
    };

    if (data.type === 'email') {
        data.emailDetails.to = {
            clients: [],
            accounts: [],
            nonPersonClients: [],
            emails: []
        };
        state.toClientList && state.toClientList.forEach(client => {
            const obj = convertClientToEmailSaveObject(client);
            client.data.npeType
                ? data.emailDetails.to.nonPersonClients.push(obj)
                : data.emailDetails.to.clients.push(obj)

        })
        state.toAccountList && state.toAccountList.forEach(acct => {
            data.emailDetails.to.accounts.push({ id: acct.data.id, email: acct.data.primary.email })
        })
        state.toEmailList && state.toEmailList.forEach(email => {
            data.emailDetails.to.emails.push({ email: email.data.email })
        })
        data.emailDetails.to = JSON.stringify(data.emailDetails.to);

        data.emailDetails.cc = {
            clients: [],
            accounts: [],
            nonPersonClients: [],
            emails: []
        };
        state.ccClientList && state.ccClientList.forEach(client => {
            const obj = convertClientToEmailSaveObject(client);
            client.data.npeType
                ? data.emailDetails.cc.nonPersonClients.push(obj)
                : data.emailDetails.cc.clients.push(obj)

        })
        state.ccAccountList && state.ccAccountList.forEach(client => {
            // data.emailDetails.cc[`account_${client.data.id}`] = client?.data?.primary?.email;
            data.emailDetails.cc.accounts.push({ id: client.data.id, email: client.data.primary.email });
        })
        state.ccEmailList && state.ccEmailList.forEach(email => {
            // data.emailDetails.cc[`email_${email.data.email}`] = email?.data?.email;
            data.emailDetails.cc.emails.push({ email: email.data.email })
        })
        data.emailDetails.cc = JSON.stringify(data.emailDetails.cc);

        data.emailDetails.bcc = {
            clients: [],
            accounts: [],
            nonPersonClients: [],
            emails: []
        };
        state.bccClientList && state.bccClientList.forEach(client => {
            const obj = convertClientToEmailSaveObject(client);
            client.data.npeType
                ? data.emailDetails.bcc.nonPersonClients.push(obj)
                : data.emailDetails.bcc.clients.push(obj)
        })
        state.bccAccountList && state.bccAccountList.forEach(client => {
            // data.emailDetails.bcc[`account_${client.data.id}`] = client?.data?.primary?.email;
            data.emailDetails.bcc.accounts.push({ id: client.data.id, email: client.data.primary.email })
        })
        state.bccEmailList && state.bccEmailList.forEach(email => {
            // data.emailDetails.bcc[`email_${email.data.email}`] = email?.data?.email;
            data.emailDetails.bcc.emails.push({ email: email.data.email })
        })
        data.emailDetails.bcc = JSON.stringify(data.emailDetails.bcc);
    }

    remote.post("/modules/crm/events", data)
        .then(resp => {
            if ((resp.data.id || resp.data.workspaceNoteId) && setEventId && !state.id) {
                setEventId(resp.data.id, resp.data.workspaceNoteId)
                displayToast && displayToast()
            }
            if (linkOrderFunction) {
                linkOrderFunction(resp.data.id, resp.data.emailDetails.id)
            }
            if (resp.refresh && refreshList) {
                resp.refresh.forEach(refresh => storage.refresh(refresh));
                displayToast && displayToast()
            }
        });
}

const convertClientToEmailSaveObject = (client) => {
    const clientEmail = client.data.email && client.data.email.includes("@") ? client.data.email : client.data.contactEmail ? client.data.contactEmail : null;
    return { id: client.data.id, email: clientEmail };

}

export const isSuggestion = (data) => {
    return data.autoFlowOn > 0 && data.automatic === 0;
}

export function getDetails(data, fields, i18n) {
    var details = [];
    for (var i = 0; i < fields.length; i++) {
        var field = fields[i];
        var value = data[field.name];
        if (!field.hidden) {
            if (field.targetProperty) {
                value = _.get(value, field.targetProperty);
            }
            details.push({
                name: field.name,
                label: i18n ? i18n.get(field.label) : field.label,
                value: value
            });
        }
    }
    return details;
}

export function guessTransition(oldValue, newValue) {
    if ((!oldValue || Object.keys(oldValue).length === 0) && newValue) {
        return "create";
    } else if ((!newValue || Object.keys(newValue).length === 0) && oldValue) {
        return "delete";
    } else if (!_.isEqual(oldValue, newValue)) {
        return "update";
    }
    return null;
}

export function renderUpdateDetails(oldValue, newValue, fields, label, i18n) {
    const i = i18n.get;
    return (
        <>
            {label}
            {
                fields.map(field => {
                    var v1 = oldValue[field.name];
                    var v2 = newValue[field.name];
                    if (!field.hidden && !isLooselyEqual(v1, v2)) {
                        if (field.targetProperty) {
                            v1 = _.get(v1, field.targetProperty);
                            v2 = _.get(v2, field.targetProperty);
                        }
                        return <EventDetailUpdateItem oldDetail={v1} newDetail={v2} status="complete" label={i(field.label)} />
                    }
                    return null;
                })
            }
        </>
    );
}

export function getOrderStatus(string) {
    switch (string) {
        case 'ACTIVE':
        case 'IN_PROGRESS':
            return "open";
            // return "active";
        case 'COMPLETED':
            return "completed";
        case 'CANCELLED':
            return "cancelled";
        case 'EXPIRED':
            return "expired";
        case 'REJECTED':
            return 'rejected'
        case 'REQUESTED':
            return 'requested'
        case 'READ':
            return 'read'
        case 'ENTERED':
            return 'entered'
        case 'PENDING':
            return 'pending'
        default:
            return "-";
    }
}

export function getAssetLogo(asset, exchange, ticker, logoOnly) {
    if (asset) {
        return <>
            <CompanyImage
                exchange={asset.exchange}
                tickerCode={asset.tickerCode}
                alt={asset.tickerCode}
                size={logoOnly ? 24 : 18}
            />
            {logoOnly ? "" : asset.tickerCode}
        </>;
    }

    if (exchange && ticker) {
        return <><CompanyImage
            exchange={exchange}
            tickerCode={ticker}
            alt={ticker}
            size={logoOnly ? 24 : 20}
        />
            {logoOnly ? "" : ticker}
        </>
    }
}

export function renderOrder(order, index, updateFunction, size) {
    if (size === "small") {
        return (
            <div key={index + "-" + (order.id || order.orderId)} className="orders-linked-row-small" style={{ fontSize: 14 }}>
                <div className="se-orders-linked-cell">
                    <ClientAccountLink className="td-account-name" type="account" clientAccount={order.account} size="small" />
                </div>
                <div className="logo">
                    <Suspense fallback={<LoadingIcon size={16} />} >
                        <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>{getAssetLogo(order.asset, order.exchange, order.ticker, true)}</div>
                    </Suspense>
                </div>
                <div className="se-orders-linked-cell action"><BuySellToken type={order.action === 'BUY' ? 'Buy' : 'Sell'} /></div>
                <Tooltip
                    placement={"top-end"}
                    offsetX={34}
                    offsetY={15}
                    label={
                        <div className='orders-info-icon-table'>
                            <div className='orders-info-icon-table-row'>
                                <div className="orders-info-icon-table-th">Creation Date</div>
                                <div className="orders-info-icon-table-th">Action Date</div>
                                <div className="orders-info-icon-table-th">Asset</div>
                                <div className="orders-info-icon-table-th">Exchange</div>
                                <div className="orders-info-icon-table-th right">Quantity</div>
                                <div className="orders-info-icon-table-th right">Unit Cost</div>
                                <div className="orders-info-icon-table-th right">Cost</div>
                            </div>
                            <div className='orders-info-icon-table-row'>
                                <div className="orders-info-icon-table-cell">{order.createdAt || order.datePlaced ? format(parseISO((order.createdAt || order.datePlaced)), "dd/MM/yyyy") : "-"}</div>
                                <div className="orders-info-icon-table-cell">{order.completedAt ? format(parseISO(order.completedAt), "dd/MM/yyyy") : "-"}</div>
                                <div className="orders-info-icon-table-cell">{order.ticker ? order.ticker : order.asset.tickerCode ? order.asset.tickerCode : "-"}</div>
                                <div className="orders-info-icon-table-cell">{order.exchange ? order.exchange : order.asset.exchange ? order.asset.exchange : "-"}</div>
                                <div className="orders-info-icon-table-cell right">{order.quantity ? order.quantity : order.quantity === 0 ? 0 : "-"}</div>
                                <div className="orders-info-icon-table-cell right">{order.unitCost || "-"}</div>
                                <div className="orders-info-icon-table-cell right">{order.cost || "-"}</div>
                            </div>
                        </div>
                    }
                >
                    <i className='material-icons info'>info</i>
                </Tooltip>
                <i className="material-icons delete" onClick={() => updateFunction(null, order)}>delete_forever</i>
            </div>
        );
    } else if (size && size === "small-medium") {
        return (
            <div key={index + "-" + (order.id || order.orderId)} className="orders-linked-row-small medium" style={{ fontSize: 14 }}>
                <div className="logo">
                    <Suspense fallback={<LoadingIcon size={16} />} >
                        <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>{getAssetLogo(order.asset, order.exchange, order.ticker, true)}</div>
                    </Suspense>
                </div>
                <div className="se-orders-linked-cell">
                    <ClientAccountLink className="td-account-name" type="account" clientAccount={order.account} size="small" />
                </div>
                <div className="se-orders-linked-cell">{order.createdAt || order.datePlaced ? format(parseISO((order.createdAt || order.datePlaced)), "dd/MM/yyyy") : "-"}</div>
                <div className="se-orders-linked-cell action"><BuySellToken type={order.action === 'BUY' ? 'Buy' : 'Sell'} /></div>
                <div>{order.exchange ? order.exchange : order.asset.exchange ? order.asset.exchange : "-"}</div>
                <div>{order.quantity ? order.quantity : order.quantity === 0 ? 0 : "-"}</div>
            </div>
        );
    } else return (
        <div key={index + "-" + (order.id || order.orderId)} className={`se-orders-linked-row ${size && size === "medium" ? "medium" : ""}`} style={{ fontSize: 14, overflowX: "scroll" }}>
            <div className="se-orders-linked-cell">{order.createdAt || order.datePlaced ? format(parseISO((order.createdAt || order.datePlaced)), "dd/MM/yyyy") : "-"}</div>
            <div className="se-orders-linked-cell">{order.completedAt ? format(parseISO(order.completedAt), "dd/MM/yyyy") : "-"}</div>
            {!size && <div className="se-orders-linked-cell">
                <ClientAccountLink className="td-account-name" type="account" clientAccount={order.account} size="small" />
            </div>}
            <div className="se-orders-linked-cell">
                <Suspense fallback={<LoadingIcon size={16} />} >
                    <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>{getAssetLogo(order.asset, order.exchange, order.ticker)}</div>
                </Suspense>
            </div>
            <div className={`se-orders-linked-cell status ${getOrderStatus(order.status)}`}>{getOrderStatus(order.status)}</div>
            <div className="se-orders-linked-cell action"><BuySellToken type={order.action === 'BUY' ? 'Buy' : 'Sell'} /></div>
            <div className="se-orders-linked-cell">{order.exchange ? order.exchange : order.asset.exchange ? order.asset.exchange : "-"}</div>
            <div className="se-orders-linked-cell number">{order.quantity ? order.quantity : order.quantity === 0 ? 0 : "-"}</div>
            <div className="se-orders-linked-cell number">{order.unitCost || "-"}</div>
            <div className="se-orders-linked-cell number">{order.cost || "-"}</div>
            {!size && <i className="material-icons se-orders-linked-cell delete" onClick={() => updateFunction(null, order)}>delete_forever</i>}
        </div>
    );
}

export function linkEventToOrderFunction(orderId, eventId, emailId, isPending, remote, toaster, storage, callBackFunction) {
    const idsObject = {
        eventId: eventId,
        emailId: emailId || null
    }
    if (isPending) {
        remote.put(`/modules/crm/provider/provider-order/${orderId}`, idsObject).then((response) => {
            if (response.success) {
                storage.refresh("/modules/crm/provider/provider-open-orders")
                toaster.toast("Event successfully linked to order")
            } else return
        })

    } else {
        remote.put(`/modules/crm/provider/provider-completed-order/${orderId}`, idsObject).then((response) => {
            if (response.success) {
                callBackFunction && callBackFunction()
                storage.refresh("/modules/crm/provider/provider-open-orders")
                toaster.toast("Event successfully linked to order")
            } else return
        })
    }
}
