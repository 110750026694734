import React, { Component } from 'react';
import "./NoResultsMessage.scss";

class NoResultsMessage extends Component {
	render() {
		const { header, noHeader, message, icon, noIcon, noMessage, className } = this.props;
		return (
			<div className={`fade-in-slow no-results-message${className ? " " + className : ""}`}>
				{noIcon ? "" : <i className="material-icons">{icon ? icon : "public_off"}</i>}
				{noHeader ? "" : <h3 >{header ? header : "No results found"}</h3>}
				{noMessage ? "" : <p>{message ? message : "Sorry, we couldn't find any matches"}</p>}
			</div>
		);
	}
}
 
export default NoResultsMessage;