import React, { Component } from 'react';
import { withI18n} from "@threeskye/global";
import { withCookies  } from 'react-cookie';

class CookieChecker extends Component {

    constructor(props) {
        super(props);

        const { cookies } = props;
        let cookiesAccepted = cookies.get('3skye.cookiesAccepted') || false;
        this.state = {
          showCookiesNotice: !cookiesAccepted
        };

        this.acceptCookies = this.acceptCookies.bind(this);
    }

    acceptCookies() {
      const { cookies } = this.props;
      const tenYears = new Date(new Date().setFullYear(new Date().getFullYear() + 10));
      cookies.set('3skye.cookiesAccepted', true, { path: '/', expires: tenYears});
      this.setState({ showCookiesNotice:false });
    }

    render() {
      return (this.state.showCookiesNotice) ? (
        <div id="cookie-notice">
            <p>This site uses cookies to provide you with a great user experience. By using 3Skye, you accept our use of cookies. &nbsp;&nbsp; <button onClick={this.acceptCookies}>Ok</button></p>
        </div>
      ) : null;
    }

}

export default withCookies(withI18n(CookieChecker));
