import React, { Component } from 'react';
import "./Icons.scss";

class DocIcon extends Component {
	render() {
		const fillA = { fill: "#1C71BD" }
		const fillB = { fill: "#77BDFA" }
		const fillC = { fill: "#2C90E6" }

		return (
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
				<g id="Layer_1" data-name="Layer 1">
					<path
						id="Shape_Copy_3"
						data-name="Shape Copy 3"
						style={fillA}
						d="M20.13,23.43H6.84A2.14,2.14,0,0,1,4.7,21.29V2.71A2.14,2.14,0,0,1,6.84.57h9.94L22.27,6V21.29A2.14,2.14,0,0,1,20.13,23.43Z"
					/>
					<path id="Shape_Copy_2" data-name="Shape Copy 2" style={fillB} d="M21.47,6.5H16.25V1.33Z" />
					<rect id="Rectangle_5_Copy_6" data-name="Rectangle 5 Copy 6" style={fillC} x="1.73" y="3.79" width="12.13" height="6.42" rx="2.14" />
					<path
						id="Path_Copy_8"
						data-name="Path Copy 8"
						style={fillB}
						d="M9.21,14.3h.56v5h1.81L13,16.85v2.48h1.68l2.51-5h.56v-.83H15.22v.83h.42l-1,1.93V13.47h-2L11,16.62V14.3h.56v-.83H9.21Z"
					/>
					<g id="MSG" className="icon-text">
						<g className="cls-4">
							<path d="M3.42,5.62H4.3A1.27,1.27,0,0,1,5.71,6.84,1.27,1.27,0,0,1,4.3,8.07H3.42Zm1,2a.75.75,0,1,0,0-1.49H4V7.59Z" />
							<path d="M6,6.84a1.31,1.31,0,0,1,2.61,0A1.31,1.31,0,0,1,6,6.84Zm2.07,0a.77.77,0,1,0-.77.8A.79.79,0,0,0,8.09,6.84Z" />
							<path
								d="M8.91,6.84a1.3,1.3,0,0,1,1.31-1.26,1.28,1.28,0,0,1,.95.41l-.38.34a.75.75,0,0,0-.57-.28.8.8,0,0,0,0,1.59.75.75,0,0,0,.57-.28l.38.34a1.32,1.32,0,0,1-.95.41A1.31,1.31,0,0,1,8.91,6.84Z"
							/>
						</g>
					</g>
				</g>
			</svg>
		);
	}
}

export default DocIcon;
