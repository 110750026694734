import React, { useEffect, useState } from 'react';
import Tooltip from '../Popups/Tooltip';
import "./EventTypeSelect.scss";

const EventTypeOptions = ({ changeType, eventTypes }) => {
	let sortedEventTypes = eventTypes
		.filter((event) => event.name !== "bulk-sharing")
		.sort((a, b) => a.order - b.order)


	return (
		<div className="event-type-options">
			{sortedEventTypes.map((type, idx) => <div key={"event-type-option-" + idx} className="type-option" onClick={() => changeType(type)}>{type.text}</div>)}
		</div>
	)
}

const EventTypeSelect = ({ eventTypes, updateEventType, currentEventType, freePosition, setSidebarExpanded, setIsEmailTemplate, propsType }) => {
	const [expanded, setExpanded] = useState(false);
	const [type, setType] = useState(propsType || "phone");

	useEffect(() => {
		if (type === "email-template") {
			setSidebarExpanded()
			setIsEmailTemplate(true)
		} else if (setIsEmailTemplate && type !== "email-template") {
			setIsEmailTemplate(false)
		}

	}, [type])

	useEffect(() => {
		if (currentEventType !== type) {
			setType(currentEventType.name)
		}
	}, [currentEventType])


	return (
		<Tooltip closeOnOutsideClick /* onOutsideClick={() => setExpanded(false)} */ toggleTooltip placement="bottom-end" label={expanded ? <EventTypeOptions eventTypes={eventTypes} changeType={(e) => { setType(e.name); updateEventType(e) }} /> : ""} popupElementClass="ets-tooltip" popupOuterClass={freePosition ? "ets-template-wrapper-popup" : "ets-wrapper-popup"}>
			<div className={`ets-inner${expanded ? " ets-expanded" : ""} ets-type-${type}`} onClick={() => setExpanded(!expanded)}>
				<i className="material-icons type-icon">{type.includes("email") ? "mail" : type === "meeting" ? "date_range" : type === "phone-call" ? "phone" : type === "reminder" ? "event" : "book"}</i>
				<i className="material-icons expand-icon">{"expand_more"}</i>
			</div>
		</Tooltip>
	);
}

export default EventTypeSelect;