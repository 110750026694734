import { getAllFieldNames, displayJSONField } from "../../../Shared/JsonParsingUtils";
import { isJSONArrayObject } from "../../../../utils/Utils";

export default function determineNotificationParser(type) {
	if (type === "change") {
		return { parseTitle: parseJsonCompare, parseSubtitle: parseJsonCompare };
	}
	return { parseTitle: (t) => t, parseSubtitle: (st) => st };
}

function parseJsonCompare(title) {
	// Example is
	// Change approved: Bank Account:
	//      [{ "primary": false, "name": "", "content": { "id": 6773527, "bank": "westpac", "accountName": "asdf", "accountNumber": "1234", "country": { "key": "NZ", "value": "New Zealand" }, "currency": "NZD" } }]
	//                                                                                                              ->
	//      [{ "primary": false, "name": "", "content": { "id": 6773527, "bank": "westpac", "accountName": "asdf", "accountNumber": "1234123", "country": { "key": "NZ", "value": "New Zealand" }, "currency": "NZD" } }]
	if (title && title.match) {
		let matches = title.match(/(\[{.+}]|null) -> (\[{.+}]|null)/g);
		if (matches) {
			title = matches.reduce((currentTitle, match) => {
				const startingIndex = currentTitle.indexOf(match);
				currentTitle = `${currentTitle.substring(0, startingIndex)}${displayJsonComparison(match)}${currentTitle.substring(startingIndex + match.length, currentTitle.length)}`;
				return currentTitle;
			}, title);
		}
	}
	return title;
}

function displayJsonComparison(match) {
	const [oldValue, newValue] = match.split(/ -> /g);

	if ((oldValue === "null" || isJSONArrayObject(oldValue)) && (newValue === "null" || isJSONArrayObject(newValue))) {
		let parsedOldValue = oldValue === "null" ? {} : JSON.parse(oldValue);
		parsedOldValue = parsedOldValue && parsedOldValue.length > 0 ? parsedOldValue[0] : {};
		const parsedOldValueContent = (parsedOldValue && parsedOldValue.content) || {};
		let parsedNewValue = newValue === "null" ? {} : JSON.parse(newValue);
		parsedNewValue = parsedNewValue && parsedNewValue.length > 0 ? parsedNewValue[0] : {};
		const parsedNewValueContent = (parsedNewValue && parsedNewValue.content) || {};

		const allFieldNames = getAllFieldNames(parsedOldValueContent, parsedNewValueContent);
		return allFieldNames
			.map((fieldName) => ({ oldV: displayJSONField(parsedOldValueContent[fieldName]), newV: displayJSONField(parsedNewValueContent[fieldName]) }))
			.filter(({ oldV, newV }) => oldV !== newV)
			.map(({ oldV, newV }) => `${oldV} -> ${newV}`)
			.join(", ");
	} else {
		return match;
	}
}
