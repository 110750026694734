import React, { Component } from "react";
import "./DropDown.scss";
import PropTypes from 'prop-types'

/* The DropDown class is a React component that renders a dropdown menu with customizable options. */
class DropDown extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isActive: !!this.props.menuIsOpen,
			isHiding: false
		};
		this.onClick = this.onClick.bind(this);
		this.showDropdown = this.showDropdown.bind(this);
		this.hideDropdown = this.hideDropdown.bind(this);
		this.handleKeyPress = this.handleKeyPress.bind(this);
	}

	static propTypes = {
		/** A value that designates the current value Selected */
		value: PropTypes.any,
		/** A string to be appended to classname props of parent div */
		classNames: PropTypes.string,
		/** A boolean that disables the dropdown */
		disabled: PropTypes.bool,
		
		filterLabel: PropTypes.bool,
		/** A boolean that makes the component width: 100% */
		fullWidth: PropTypes.bool,
		/** makes the component transparent */
		transparent: PropTypes.bool,
		/** A component that replaces the default control input */
		customControl: PropTypes.element
	}

	onClick(itemClick, e) {
		itemClick && itemClick(e);
	}

	showDropdown() {
		this.setState({ isActive: true });
		if(this.props.onOpenMenu) {
			this.props.onOpenMenu()
		}
	}

	hideDropdown() {
		this.setState({ isHiding: true });
		if(this.props.onCloseMenu) {
			this.props.onCloseMenu()
		}
		setTimeout(() => {
			this.setState({ isActive: false, isHiding: false });
		}, 100);
	}

	handleKeyPress = (event) => {
		if (event.key === 'Escape') {
			this.hideDropdown()
		}
	}

	render() {
		let className = "dropdown-selection-list-container";
		let classNames = "dropdown-container";
		let allocationDropdown;

		const dropdownMenuStyle = this.props.dropdownMenuStyle || null

		if (this.props.classNames) {
			classNames += " " + this.props.classNames;
		}
		if (this.props.disabled) {
			classNames += " dd-disabled";
		}
		if (this.props.filterLabel) {
			allocationDropdown = <div className="filter-inner-label">{this.props.filterLabel}</div>;
		}
		if (this.state.isActive) {
			className += " dropdown-selection__active";
		} else {
			className += "";
		}
		if (this.props.fullWidth) {
			classNames += " dropdown-full-width";
		}
		if (this.state.isHiding) {
			className += " fade-out";
		}
		if (this.state.transparent) {
			className += " transparent";
		}
		if (this.props.transparent) {
			classNames += " dropdown-transparent";
		}

		return (
			<div
				onBlur={this.hideDropdown}
				className={classNames}
				onClick={this.state.isActive ? this.hideDropdown : this.showDropdown}
				onMouseEnter={this.props.clickable ? null : this.showDropdown}
				onMouseLeave={this.props.clickable ? null : this.hideDropdown}
				style={this.props.style || null}
				tabIndex={0}
				onKeyDown={this.handleKeyPress}
			>
				{allocationDropdown}
				{this.props.customControl
					? this.props.customControl
					: <div
						className={`dropdown-value-wrapper flex-center${this.state.isActive ? ' dropdown-value__active' : ''}${!this.props.value ? " placeholder-value" : ""}`}
						style={this.props.labelFontSize ? {fontSize: this.props.labelFontSize} : this.props.minWidth ? {minWidth: this.props.minWidth} : this.props.inputDropdown && { padding: "9px 12px"}}
						id={this.props.labelId || null}
					>
						{this.props.value ? this.props.value : this.props.placeholder ? this.props.placeholder : "Select"}
						<i className="material-icons" style={this.props.iconFontSize ? {fontSize: this.props.iconFontSize} : {}}>expand_more</i>
					</div>}
				<div className={className} style={dropdownMenuStyle}>
					<ul className="dropdown-selection-list" onClick={this.hideDropdown} style={{margingTop: this.props.marginTop || "", minWidth: this.props.minWidth || ""}}>
						{this.props.children && this.props.children.map((child, idx) => {
							return React.cloneElement(child, {
								onClick: e => this.onClick(child.props.onClick, e),
								key: idx
							});
						})}
					</ul>
				</div>
			</div>
		);
	}
}

export default DropDown;
