import React from 'react';
import "./EventModalTitleField.scss";
import EventTypeSelect from './EventTypeSelect';

const EventModalTitleField = ({value, placeholder, onChange, updateEventType, eventTypes, currentEventType, titleRef}) => {
	return (
		<div id="emtf-wrapper">
			<input ref={titleRef} type="text" placeholder={placeholder} value={value} onChange={onChange} autoFocus />
			<EventTypeSelect type="phone" updateEventType={updateEventType} eventTypes={eventTypes} currentEventType={currentEventType} />
		</div>
   );
}

export default EventModalTitleField;