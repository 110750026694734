import React from 'react';
import Topbar from '../components/Topbar/Topbar';
import "./TopNav.scss";

const TopNav = ({ logout })=>{
	return <div id="top-nav-banner">
		<div className="top-nav-inner">
			<img className="top-nav-logo" src="/img/logo.png"  />
			<Topbar logout={logout} />
		</div>
	</div>
}

export default TopNav;