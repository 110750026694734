import React, { Component } from "react";
import { withI18n } from "@threeskye/global";
import { withRouter } from "react-router-dom";
import Tooltip from "../Popups/Tooltip";

class SideNavLinks extends Component {
	render() {
		const i = this.props.i18n.get;
		const dismiss = this.props.selectionMade || (()=>{});

		const links = this.props.links;

		const linksWithProps = React.Children.map(links, link=>
			React.cloneElement(link, { dismiss })
		);

		return (
			<React.Fragment>
				<div id="side-nav-links" className={this.props.right ? "side-nav-links-right" : ""}>
					<ul>{linksWithProps}</ul>
				</div>
				{this.props.logout && (
					<div id="side-nav-footer">
						<ul>
							<li onClick={this.props.logout}>
								<Tooltip active rightCenter sidebarLink label={i("Logout")} placement="right" delay={500}>
									<i className="material-icons">logout</i>
									<p>{i("Logout")}</p>
								</Tooltip>
							</li>
						</ul>
					</div>
				)}
			</React.Fragment>
		);
	}
}

export default withRouter(withI18n(SideNavLinks));
