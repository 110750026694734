import React, { Component } from "react";
import "./DropDownMenu.scss";
import { withThreeSkyeGlobal } from "@threeskye/global";
import { withRouter } from 'react-router-dom';
import withRouteChange from "@threeskye/route-change";

class DropDownMenu extends Component {
	goToPage(page) {
		this.props.changeRoute(page);
	}
	render() {
		const i = this.props.i18n.get;
		return (
			<div className="dropdown-menu-wrapper">
				<ul>
					<li onClick={() => this.goToPage("/profile/details")}>{i("Profile")}</li>
					{/*<li onClick={() => this.goToPage("/dashboard")}>{i("Dashboard")}</li>
					<li onClick={() => this.goToPage("/accounts")}>{i("Accounts")}</li>
					<li onClick={() => this.goToPage("/clients")}>{i("Clients")}</li>*/}
					<li onClick={() => this.goToPage("/setup")}>{i("Setup")}</li>
					{/* <li>{i("About")}</li> */}
					<li onClick={this.props.logout}>{i("Logout")}</li>
				</ul>
			</div>
		);
	}
}

export default withRouter(withThreeSkyeGlobal(withRouteChange(DropDownMenu)));
