import React, { Component } from 'react';
import { withI18n, withStorage, withRemote } from "@threeskye/global";
import { orderedEventTypes } from "../../utils/EventUtils"
import { ModalTypes } from '../../utils/ModalUtils';
import "./DropDownMenu.scss";

class CreateDropDownMenu extends Component {
	openModal(type, autoTaggedAccount, autoTaggedClient, orderToBeLinkedTo, callBackFunction) {
		this.props.storage.get("crm.modals").show(ModalTypes.CreateEvent, {
			eventType: type,
			autoTaggedAccount: autoTaggedAccount,
			autoTaggedClient: autoTaggedClient,
			orderToBeLinkedTo: orderToBeLinkedTo,
			callBackFunction: callBackFunction
		})
	}
	openBulkSharingModal(type) {
		this.props.storage.get("crm.modals").show(ModalTypes.BulkSharingModal, {})
	}
	render() {
		return (
			<div className="dropdown-menu-wrapper" id="create-btn-dropdown">
				<h5>Create New Event</h5>
				<ul>
					{orderedEventTypes("topbarCreateButton").map(eventType => {
						//could re-use the eventType.goodFor as a tooltip
						return <li
							key={eventType.name}
							onClick={() => eventType.name === "bulk-sharing" ? this.openBulkSharingModal() :
								this.openModal(
									eventType.name,
									this.props.autoTaggedAccount,
									this.props.autoTaggedClient,
									this.props.orderToBeLinkedTo,
									this.props.callBackFunction
								)}>
							{eventType.text}
						</li>
					})}
				</ul>
			</div>
		);
	}

}
export default withStorage(withI18n(CreateDropDownMenu));
