const ClientAccountTypes = { ACCOUNT: "account", CLIENT: "client", NON_PERSON: "npe" };

export function getClientAccountType(clientAccount, adviserGroupToken) {
	if (adviserGroupToken){
		return ClientAccountTypes.ACCOUNT;
	} else if (!clientAccount.type) {
		return ClientAccountTypes.ACCOUNT;
	} else if (clientAccount.type === "PERSON") {
		return ClientAccountTypes.CLIENT;
	} else {
		return ClientAccountTypes.NON_PERSON;
	}
}

export default ClientAccountTypes;
