import React from 'react';
import { withThreeSkyeGlobal } from "@threeskye/global";
import "./SideNavIcon.scss";
import { isMobile } from '../../utils/Utils';

const SideNavIcon = ({i18n, textKey, icon, active, onClick, counter, className }) => {
	const i = i18n.get;

	return (
		<div onClick={(isMobile() && active) ? null : onClick} className={`side-nav-icon${active ? " active-icon" : ""}${className ? ' ' + className : ''}`}>
			{counter && <div className="sni-counter">{counter}</div>}
			<i className="material-icons">{icon}</i>
			<p>{i(textKey)}</p>
		</div>
   );
}

export default withThreeSkyeGlobal(SideNavIcon);