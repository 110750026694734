import React from 'react';
import "./AssetLogo.scss";

const AssetLogo = ({logo, alt, size}) => {
	return (
		<div className={`asset-logo-wrapper${!logo ? " no-logo" : ""}`} style={{width: size, height: size, minWidth: size, minHeight: size}}>
			{logo ? <img src={logo} alt={alt ? alt : "Asset logo"} /> : alt ? alt.charAt(0) : ""}
		</div>
   );
}

export default AssetLogo;