import React, { ref, PropsWithChildren } from 'react'
import ReactDOM from 'react-dom'


export const Button =
  (
    {
      className,
      active,
      reversed,
      ...props
    }
  ) => (
    <div
      {...props}
      ref={ref}
      className={`toolbar-button${active ? " toolbar-button__active" : ""} ${className ? className : ""}`}
    />
  )


export const EditorValue =
  (
    {
      className,
      value,
      ...props
    }
  ) => {
    const textLines = value.document.nodes
      .map(node => node.text)
      .toArray()
      .join('\n')
    return (
      <div
        ref={ref}
        {...props}
        style={{
          margin: '30px -20px 0'
        }}
      >
        <div
          style={{
            fontSize: '14px',
            padding: '5px 20px',
            color: '#404040',
            borderTop: '2px solid #eeeeee',
            background: '#f8f8f8'
          }}
        >
          Slate's value as text
        </div>
        <div>

          {textLines}
        </div>
      </div>
    )
  }


export const Icon =
  (
    { className, ...props }
  ) => (
    <span
      {...props}
      ref={ref}
      className={cx(
        'material-icons',
        className,
        css`
          font-size: 18px;
          vertical-align: text-bottom;
        `
      )}
    />
  )


export const Instruction =
  (
    { className, ...props }
  ) => (
    <div
      {...props}
      ref={ref}
      className={cx(
        className,
        css`
          white-space: pre-wrap;
          margin: 0 -20px 10px;
          padding: 10px 20px;
          font-size: 14px;
          background: #f8f8e8;
        `
      )}
    />
  )


export const Menu =
  (
    { className, ...props }
  ) => (
    <div
      {...props}
      ref={ref}
      className={className}
    />
  )


export const Portal = ({ children }) => {
  return typeof document === 'object'
    ? ReactDOM.createPortal(children, document.body)
    : null
}

export const Toolbar =
  (
    { className, ...props }
  ) => (
    <Menu
      {...props}
      ref={ref}
      className="editor-toolbar"
    />
  )


const cx = () => null;
const css = () => null;