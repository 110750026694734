import React from 'react';
const SummaryValue = (props) => {

    let value = props.value;

    if (!value && value !== 0) {
        return <i className="material-icons cached">cached</i>
    }

    const zero = props.zero || (props.noPrefix ? "0" : "$0");
    const millionDp = props.millionsPlaces || 2;
    const billionDp = props.billionsPlaces || 3;

    if (value === 0) {
        return zero;
    } else {
		const negative = value < 0;
		if (negative) 
			value = -value;

		const prefix = props.noPrefix ? "" : negative ? "-$": "$";

		if (value >= 1000000000) {
			return prefix + (value / 1000000000.).toFixed(billionDp) + "b";
		} else if (value >= 1000000) {
			return prefix + (value / 1000000.).toFixed(millionDp) + "m";
		} else if (value >= 1000) {
			return prefix + (value / 1000.).toFixed(props.decimalPlaces || 0) + "k";
		} else {
			var amount = (value / 1.).toFixed(props.decimalPlaces || 0);
			return prefix + amount;
		}
    }

}

export default SummaryValue;