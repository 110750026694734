import React, { Component } from "react";
import "./Toast.scss";

class ToastNotification extends Component {
	render() {

		let containerClass = "toast-list-container";
		if (this.props.visible)
			containerClass += " toast-fade-in";

		return (
			<div className={containerClass}>
				<div className="toast-container">
					<div className="toast-content">
						<div className="toast-message">{this.props.message}</div>
						<div className="toast-close" onClick={this.props.close}>
							<i className="material-icons">close</i>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default ToastNotification;
