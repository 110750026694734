import React, { Component } from "react";
import "./MinimisedTab.scss";

class MinimisedTab extends Component {
	render() {
		return (
			<div className="minimised-tab" onClick={this.props.onClick}>
				{this.props.children}
			</div>
		)
	}
}

export default MinimisedTab;