import React from 'react';
import Button from "@threeskye/buttons";

class SidebarPrompt extends React.PureComponent {

    //TODO button control ...
    render() {
        return <div className="sidebar-prompt-container">
            {this.props.prompt}
            <div className="button-container split-buttons mt-2">
                <Button	type="success" rounded>Yes</Button>
                <Button	type="warning" rounded>No</Button>
            </div>
        </div>;
    }
}

export default SidebarPrompt;
