import React, { Component } from "react";
import "./MobileMenu.scss";
import SideNavLinks from "../SideNav/SideNavLinks.js";
import ProfileImage from '../Shared/ProfileImage';
import { withStorage } from "@threeskye/global";

class MobileMenu extends Component {
	constructor(props) {
		super(props);

		this.state = {
			user: { preferredName: "_", familyName: "_", email: "" }
		}

		this.initialise.bind(this)();
	}

	initialise() {
		this.props.storage
			.getOrFetch("/users/current-user")
			.then(user => this.setState({ user: user, userName: user.fullName }));
	}

	render() {
		const classNameOne = this.props.visible ? "mm-slide-overlay" : "";
		const classNameTwo = this.props.visible ? "mm-slide-animation" : "";

		return (
			<div id="mobile-menu-container" className={classNameOne}>
				<div id="mobile-menu" className={classNameTwo}>
					<div className="mm-header">
						<img
							id="mm-logo"
							src="/img/logo.png"
							alt="logo"
						/>
						<div id="mm-close" className="material-icons" onClick={()=>this.props.storage.put("crm.showMobileMenu", false)}>
							close
						</div>
					</div>
					<div className="mm-profile-container">
						<ProfileImage user={this.state.user} />
						<div className="mm-profile-details">
							<h2>{this.state.userName}</h2>
							<p>{this.state.user.email}</p>
						</div>
					</div>
					<div className="mm-links">
						<SideNavLinks selectionMade={()=>this.props.storage.put("crm.showMobileMenu", false)} links={this.props.links} logout={this.props.logout}/>
					</div>
				</div>
			</div>
		);
	}
}

export default withStorage(MobileMenu);
