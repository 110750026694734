import React, { Component } from 'react';
import "./Icons.scss";

class MsgIcon extends Component {
	render(){
		const fillA = { fill: "#7F52A4" };
		const fillB = { fill: "#A06BCA" };
		const fillC = { fill: "#53326D" };

		return (
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
				<g id="Layer_1" data-name="Layer 1">
					<path
						id="Shape_Copy_258"
						data-name="Shape Copy 258"
						style={fillA}
						d="M20.24,23.43H7a2.14,2.14,0,0,1-2.14-2.14V2.71A2.14,2.14,0,0,1,7,.57h9.94L22.38,6V21.29A2.14,2.14,0,0,1,20.24,23.43Z"
					/>
					<path id="Shape_Copy_257" data-name="Shape Copy 257" style={fillB} d="M21.63,6.6H16.41V1.43Z" />
					<rect id="Rectangle_5_Copy_214" data-name="Rectangle 5 Copy 214" style={fillC} x="1.62" y="3.79" width="12.13" height="6.42" rx="2.14" />
					<g id="MSG-2" className="cls-4">
						<g className="icon-text">
							<path d="M4,5.62h.53L5.31,7l.75-1.38h.53V8.07H6.06V6.54L5.31,7.93,4.55,6.54V8.07H4Z" />
							<path
								d="M6.93,7.71l.3-.36A1.23,1.23,0,0,0,8,7.67c.27,0,.4-.12.4-.28s-.14-.23-.52-.31S7,6.81,7,6.34s.37-.76.91-.76a1.43,1.43,0,0,1,1,.33l-.28.38A1.16,1.16,0,0,0,7.93,6c-.22,0-.36.12-.36.27s.15.23.53.32.87.27.87.73-.35.77-1,.77A1.61,1.61,0,0,1,6.93,7.71Z"
							/>
							<path
								d="M10.47,6.69h1.09v1a1.41,1.41,0,0,1-1,.41A1.32,1.32,0,0,1,9.25,6.84a1.31,1.31,0,0,1,1.32-1.26,1.41,1.41,0,0,1,1,.41l-.38.34a.85.85,0,0,0-.61-.28.8.8,0,0,0,0,1.59.86.86,0,0,0,.48-.16V7.1h-.58Z"
							/>
						</g>
					</g>
					<path
						id="_copy_3"
						data-name=" copy 3"
						style={fillB}
						d="M17.06,20.5H10.18a.86.86,0,0,1-.6-.25.83.83,0,0,1-.26-.61v-4.3a.82.82,0,0,1,.12-.43.79.79,0,0,1,.3-.3l3.88-2.43,3.88,2.43a.86.86,0,0,1,.3.3.82.82,0,0,1,.12.43v4.3a.87.87,0,0,1-.86.86Zm-6.88-4.15v3.29h6.88V16.35l-3.44,2.16Zm3.44-3.15-3.44,2.14,3.44,2.15,3.44-2.15Z"
					/>
				</g>
			</svg>
		);
	}
}
export default MsgIcon;
