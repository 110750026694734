import React, { useEffect, useState } from 'react';
import { SideNavSection } from '../SideNavExtension';
import { withStorage, withI18n, withRemote } from "@threeskye/global";
import { withRouter } from "react-router-dom";
import withRouteChange from "@threeskye/route-change";
import { withData } from "../../../crm/ThreeSkyeCRM";
import SingleNotification from '../../Dashboard/DashboardModules/NotificationList/SingleNotification';
import "./BookmarksExtension.scss";
import ExtensionEmptyMessage from './ExtensionEmptyMessage';
import LoadingIcon from '@threeskye/loading-icon';
import FlexWrapper from '../../Shared/FlexWrapper';

const BookmarksExtension = (props) => {

	const [markedAccounts, setMarkedAccounts] = useState([])
	const [markedClients, setMarkedClients] = useState([])
	const [markedNonPersonClients, setMarkedNonPersonClients] = useState([])
	const [loading, setLoading] = useState(true)

	useEffect(() => {
		Promise.all([
			props.storage.getSetting("crm-accounts-bookmarked"),
			props.storage.getSetting("crm-clients-bookmarked"),
			props.storage.getSetting("crm-non-persons-bookmarked")
		]).then(([acc, cli, npe]) => {
			updateAccountsList(acc)
			updateClientsList(cli)
			updateNonPersonsList(npe)
			setLoading(false)
		})

		props.storage.watchSetting("crm-accounts-bookmarked", updateAccountsList);
		props.storage.watchSetting("crm-clients-bookmarked", updateClientsList);
		props.storage.watchSetting("crm-non-persons-bookmarked", updateNonPersonsList);
		return () => unmountComponent()
	}, [])

	const unmountComponent = () => {
		props.storage.unwatchSetting("crm-clients-bookmarked", updateClientsList);
		props.storage.unwatchSetting("crm-accounts-bookmarked", updateAccountsList);
		props.storage.unwatchSetting("crm-non-persons-bookmarked", updateNonPersonsList);
	}


	const updateAccountsList = (bookmarked) => {
		bookmarked = bookmarked || [];
		const accounts = bookmarked.map(id => props.getAccountById(id, props.storage));
		Promise.all(accounts).then(list => {
			list.sort((a, b) => a.name.localeCompare(b.name));
			setMarkedAccounts(list);
		});
	}

	const updateClientsList = (bookmarked) => {
		bookmarked = bookmarked || [];
		const clients = bookmarked.map(id => props.getClientById(id, props.storage));
		Promise.all(clients).then(list => {
			list.sort((a, b) => a.sortName.localeCompare(b.sortName));
			setMarkedClients(list);
		});
	}

	const updateNonPersonsList = (bookmarked) => {
		bookmarked = bookmarked || [];
		const npes = bookmarked.map(id => props.getNonPersonClientById(id, props.storage));
		Promise.all(npes).then((list) => {
			list.sort((a, b) => a.sortName.localeCompare(b.sortName));
			setMarkedNonPersonClients(list);
		});
	}

	const removeAccountBookmark = (id) => {
		props.storage.removeFromSettingsList("crm-accounts-bookmarked", id);
	}

	const removeClientBookmark = (id) => {
		props.storage.removeFromSettingsList("crm-clients-bookmarked", id);
	}

	const removeNonPersonBookmark = (id) => {
		props.storage.removeFromSettingsList("crm-non-persons-bookmarked", id);
	}

	const onClick = (type, entity) => {
		switch (type) {
			case "account":
				props.changeRoute(props.getAccountLink(entity))
				break;
			case "client":
				props.changeRoute(props.getClientLink({...entity, email: entity.userId}))
				break;
			case "nonpersonal":
				props.changeRoute(props.getNonPersonLink(entity))
				break;
			default:
				break;
		}
	}
	if (loading) {
		return (
			<div className="side-nav-wrapper">
				<FlexWrapper fullWidth justify="center"><LoadingIcon size={40} /></FlexWrapper>
			</div>
		)
	}

	return (
		<div className="side-nav-wrapper">
			{/* @ANDY: If loading */}
			{/* @ANDY: If empty */}
			{(!markedAccounts || markedAccounts.length < 1) && (!markedClients || markedClients.length < 1) && (!markedNonPersonClients || markedNonPersonClients.length < 1) && (
				<ExtensionEmptyMessage icon="bookmark_border" iconColor="#0E8EFD" header="You have no bookmarks yet" message="Try clicking the bookmark icon on an account/client page" />
			)}
			{markedAccounts && markedAccounts.length > 0 &&
				<SideNavSection header="Accounts">
					{markedAccounts &&
						markedAccounts.map((bookmark, idx) => (
							<SingleNotification
								className="bookmark-icon-account"
								title={bookmark.name}
								subtitle={""}
								icon={"account_balance"}
								onClick={() => onClick("account", bookmark)}
								onDelete={() => removeAccountBookmark(bookmark.id)}
								deleteOnClick={false}
								key={idx}
								date={"notification.date"}
								data={"notification"}
								type="bookmark"
							/>
						))}
				</SideNavSection>
			}
			{markedClients && markedClients.length > 0 &&
				<SideNavSection header="Clients">
					{markedClients &&
						markedClients.map((bookmark, idx) => (
							<SingleNotification
								className="bookmark-icon-client"
								title={bookmark.name}
								subtitle={""}
								icon={"person"}
								onClick={() => onClick("client", bookmark)}
								onDelete={() => removeClientBookmark(bookmark.id)}
								deleteOnClick={false}
								key={idx}
								date={"notification.date"}
								data={"notification"}
								type="bookmark"
							/>
						))}
				</SideNavSection>
			}
			{markedNonPersonClients && markedNonPersonClients.length > 0 &&
				<SideNavSection header="Non-Person Clients">
					{markedNonPersonClients &&
						markedNonPersonClients.map((bookmark, idx) => (
							<SingleNotification
								className="bookmark-icon-npe"
								title={bookmark.name}
								subtitle={""}
								icon={"business"}
								onClick={() => onClick("nonpersonal", bookmark)}
								onDelete={() => removeNonPersonBookmark(bookmark.id)}
								deleteOnClick={false}
								key={idx}
								date={"notification.date"}
								data={"notification"}
								type="bookmark"
							/>
						))}
				</SideNavSection>
			}
		</div>
	);
}

export default withData(withRouter(withStorage(withI18n(withRemote(withRouteChange(BookmarksExtension))))));