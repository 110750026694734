import React, { Component } from "react";

class SidebarList extends Component {
	render() {
		let classNames="sidebar-list";
		//let sectionHeader = "";

		if(this.props.sectionHeader) {
			//sectionHeader = this.props.sectionHeader;
		}
		if(this.props.classNames) {
			classNames += " " + this.props.classNames;
		}
		return(
			<div className="sidebar-list-container">
				{/*
				On going discussion has been to remove this header from the sidebar list
				<div className="sidebar-section-header">
					<h3 >{sectionHeader}</h3>
				</div> */}
				<ul className={classNames}>
					{this.props.children}
				</ul>
			</div>
		)
	}
}

export default SidebarList;