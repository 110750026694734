import React from "react";
import DocIcon from './DocIcon';
import XlsIcon from './XlsIcon';
import PptIcon from './PptIcon';
import PdfIcon from './PdfIcon';
import FileIcon from './FileIcon';
import PngIcon from "./PngIcon";
import EmlIcon from "./EmlIcon";
import MsgIcon from "./MsgIcon";
import ZipIcon from "./ZipIcon";
import JpgIcon from "./JpgIcon";
import CsvIcon from "./CsvIcon";

export function svgElementFor(extension) {
	if (!extension) {
		return <FileIcon/>;
	}
	switch (extension.toLowerCase()) {
		case "docx":
		case "doc":
		case "odt":
		case "application/msword":
		case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
			return <DocIcon/>;
		case "xlsx":
		case "xls":
		case "xlsm":
		case "xlsb":
		case "ods":
		case "application/vnd.ms-excel":
		case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
			return <XlsIcon/>;

		case "ppt":
		case "pptx":
			return <PptIcon/>;
		case "application/pdf":
		case "pdf":
			return <PdfIcon/>;
		case "png":
			return <PngIcon/>;
		case "jpg":
			return <JpgIcon/>;
		case "eml":
			return <EmlIcon/>;
		case "msg":
			return <MsgIcon/>;
		case "zip":
			return <ZipIcon/>;
		case "csv":
			return <CsvIcon/>;
		default:
			return <FileIcon/>;
	}
}
