import React from 'react';
import FlexWrapper from '../Shared/FlexWrapper';
import "./SideNavExtension.scss";

export const SideNavSectionHeader = ({ header, icon }) => {
	return (
		<div className="side-nav-section-header">
			{icon && <i className="extension-icon material-icons">{icon}</i>} <p>{header}</p>
		</div>
	);
};

export const SideNavHeader = ({ header, actions }) => {
	return (
		<FlexWrapper justify="space-between" gap="s" align="flex-start">
			{header && <p className="extension-header">{header}</p>}
			<FlexWrapper gap="m">{actions}</FlexWrapper>
		</FlexWrapper>
	);
};

export const SideNavSection = ({ header, children, icon }) => (
	<div className="side-nav-section">
		{header && <SideNavSectionHeader header={header} icon={icon} />}
		<div className="side-nav-inner">{children}</div>
	</div>
);

const SideNavExtension = ({ content, fixed, header, left, right, hidden, actions, isEmailTemplate, isMobile }) => {
	return (
		<div id="side-nav-extension" className={`${fixed ? "extension-fixed" : ""}${left ? " extension-left" : ""}${right ? " extension-right" : ""}${hidden ? " side-extension-hidden" : ""} ${isEmailTemplate && isMobile ? "email-template-mobile-view" : ""}`}>
			{header && <SideNavHeader header={header} actions={actions} />}
			<div className="side-nav-extension-inner">{content}</div>
		</div>
	);
}

export default SideNavExtension;