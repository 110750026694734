import React from 'react';
import SideNavLinks from '../components/SideNav/SideNavLinks';
import "./SideNav.scss";

const SideNav = ({ nav, logout, right })=>{
	return <div id="side-nav-bar" className={right ? "side-nav-bar-right" : ""}>
		<div className="side-nav-bar-inner">
			<SideNavLinks right={right} links={nav} logout={!right && logout} />
		</div>
	</div>
}

export default SideNav;