import React, { useEffect, useState } from 'react';
import SingleNotification from './SingleNotification';
import { withThreeSkyeGlobal } from "@threeskye/global";
import { withData } from '../../../../crm/ThreeSkyeCRM';
import withRouteChange from '@threeskye/route-change';
import { GroupedChangeNotifications } from './GroupedChangeNotifications'
import ClientAccountLink from '../../../ClientAccount/ClientAccountLink';


const AssembleRequestNotifications = (props) => {
    const { list, getIcon, onClick, onDelete, timePeriod, storage, getClientById, getAccountById, getNonPersonClientById } = props
    const [addedList, setAddedList] = useState([])


    useEffect(() => {
        const newList = list && list.map(notification => {
            return storage.getOrFetch(notification.modalParams).then(params => {
                let change = params.workflow.changes[0]
                let accountPromise = null;
                let clientPromise = null;
                let nonPersonClientPromise = null;

                if (change.changedAccount) {
                    accountPromise = getAccountById(change.changedAccount.id, props.storage);
                } else if (change.changedUser) {
                    clientPromise = getClientById(change.changedUser.id, props.storage);
                } else {
                    nonPersonClientPromise = getNonPersonClientById(change.changedEntity.id, props.storage);
                }

                return Promise.all([
                    accountPromise,
                    clientPromise,
                    nonPersonClientPromise

                ]).then((resp) => {
                    const notificationWithExtraInfo = change.changedAccount && change.changedAccount.id === resp[0].id ?
                        { ...notification, clientAccountToken: resp[0] } : change.changedUser && change.changedUser.id === resp[1].id ?
                            { ...notification, clientAccountToken: resp[1] } : change.changedEntity && change.changedEntity.id ?
                                { ...notification, clientAccountToken: resp[2] } : notification
                    return notificationWithExtraInfo
                })
            })
        })
        Promise.all(newList).then((listWithToken) => setAddedList(listWithToken))
    }, [list])


    return (
        <>
            {
                addedList && (addedList.length >= 3
                    ? <GroupedChangeNotifications onClick={onClick} notifications={addedList} onDelete={onDelete} timePeriod={timePeriod} deleteOnClick={false} groupIcon={getIcon("workflow")} />
                    : addedList.map((notification, idx) => (
                        <SingleNotification
                            title="Feedback Requested"
                            subtitle={<ClientAccountLink size="small" clientAccount={notification.clientAccountToken} />}
                            // subtitle={format(parseISO(notification.triggeredDate), "dd/MM/yyyy")}
                            icon={getIcon ? getIcon("workflow") : "update"}
                            onClick={onClick}
                            onDelete={onDelete}
                            deleteOnClick={false}
                            key={idx}
                            date={notification.date}
                            data={notification}
                            type={notification.type}
                        // tooltip={substractText(notification)}
                        />
                    ))
                )
            }
        </>
    )
}

export default withData(withThreeSkyeGlobal(withRouteChange(AssembleRequestNotifications)));


