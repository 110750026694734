import React, { useEffect, useState } from 'react';
import { withI18n, withStorage, withRemote, withLoading } from "@threeskye/global";
import LoadingComponent from '@threeskye/global/dist/LoadingComponent';
import LoginForm from './LoginForm';
import MaintenanceMode from './MaintenanceMode';
import NotFound from './NotFound';
import SetPasswordPage from './SetPasswordPage';
import ForgotPasswordCodeEntry from './ForgotPasswordCodeEntry';
import ForgotPasswordSMS from './ForgotPasswordSMS';
import ForgotPasswordEmail from './ForgotPasswordEmail';
import WelcomePage from './WelcomePage';
import PasswordChangedPage from './PasswordChangedPage';
import InvalidCodePage from './InvalidCodePage';
import { withRouter } from 'react-router-dom';
import UnauthorisedPage from './UnauthorisedPage';


const AuthChecker = (props) => {

	const isLoggedIn = sessionStorage.getItem("3skye.loggedin") === "true";
	const [localLoading, setLocalLoading] = useState(true);
	const [loginRequired, setLoginRequired] = useState(true);
	const [loggedIn, setLoggedIn] = useState(isLoggedIn);
	const [maintenanceMode, setMaintenanceMode] = useState(false);
	const [maintenanceMessage, setMaintenanceMessage] = useState("");
	const [fourOhFour, setFourOhFour] = useState(false);
	const [forgotPasswordPage, setForgotPasswordPage] = useState(false);      		/* password recovery through SMS */
	const [forgotPasswordPageEmail, setForgotPasswordPageEmail] = useState(false); 	/* password recovery through Email */
	const [forgotPasswordCodeEntry, setForgotPasswordCodeEntry] = useState(false);	/* secret code entry page */
	const [setPasswordPage, setSetPasswordPage] = useState(false);					/* actual set password page */
	const [welcomePage, setWelcomePage] = useState(false);							/* first time login */
	const [passwordChangedPage, setPasswordChangedPage] = useState(false);			/* Success page to show when password changed */
	const [invalidCodePage, setInvalidCodePage] = useState(false);					/* Error page if wrong 2fa code entered */
	const [recoveryMethod, setRecoveryMethod] = useState("mobile");
	// const [shouldAutoLogin, setShouldAutoLogin] = useState(true);
	const [app, setApp] = useState(null);

	const { storage, loading, installComponent, remote } = props;

	useEffect(() => {
		window.addEventListener("popstate", determineRoute);
		console.log("Auth checker registering a logout handler")
		remote.registerLogoutHandler(logout);
		initialise();
	}, [])

	const initialise = () => {
		storage.getOrFetch("/configuration")
			.then(config => {
				if (config.maintenanceMode) {
					setMaintenanceMode(true);
					setMaintenanceMessage(config.maintenanceMessage);
					setLocalLoading(false);
				} else {
					initApp();
				}
				loading(false);
			});
	}


	const initApp = () => {
		determineRoute()
			.then(({ app, route }) => {
				if (!app) {
					return;
				}
				installComponent(app);
				storage.getOrFetch("/configuration/" + app + "/valid-routes")
					.then(routes => getAccessFor(route, routes))
					.then(access => {
						if (access === null || access === undefined) {
							setFourOhFour(true);
							setLocalLoading(false);
						} else {
							switch (access) {
								case 'PUBLIC':
									setLoginRequired(false);
									setLocalLoading(false);
									setApp(app);
									break;
								default:
									//TODO handle restricted
									setLoginRequired(true);
									setLocalLoading(false);
									setApp(app);
									break;
							}
						}
					});
			});
	}

	const getAccessFor = (route, routes) => {
		for (var providedRoute in routes) {
			if (!routes.hasOwnProperty(providedRoute))
				continue;

			if (providedRoute.indexOf("*") > -1) {
				var match = providedRoute.substring(0, providedRoute.indexOf("*"));
				if (route.indexOf(match) === 0) {
					return routes[providedRoute];
				}
			} else if (providedRoute === route) {
				return routes[providedRoute];
			}
		}
		return null;
	}

	const onLogin = () => {
		setLoggedIn(true);
		sessionStorage.setItem("3skye.loggedin", "true");
	}

	const logout = () => {
		console.log("Doing the auth checker logout");
		setLoggedIn(false);
		setLocalLoading(true);
		sessionStorage.clear();
		sessionStorage.setItem("3skye.loggedin", "false");
		//re-initialise to see if we're now on a need-to-log-in page or not
		initialise();
		//storage.logout();
	}

	const switchPage = (page) => {
		var url = window.location.href;
		const i18nStart = url.indexOf("/", "https://x".length);
		const appStart = url.indexOf("/", i18nStart + 1) + 1;

		const i18n = url.substring(i18nStart, appStart);

		window.history.pushState("", "", i18n + page);
		initApp();
	}

	const wsLogin = () => {
		const url = window.location.href;
		console.log("URL is ", url);
	
		storage.getOrFetch("/configuration").then(config=> {
			const locale = config.defaultLocale;
			const app = config.defaultApp;
			const index = url.indexOf(locale+"/"+app);
			if (index < 0) {
				console.log("Didn't find "+(locale+"/"+app)+" so no redirect")
				window.location.href = "/api/um/ws-redirect?redirect=";
				return;
			} else {
				console.log("found "+(locale+"/"+app)+" so redirecting to "+url.substring(url.indexOf(locale+"/"+app)+locale.length+app.length+2))
				window.location.href = "/api/um/ws-redirect?redirect="+url.substring(url.indexOf(locale+"/"+app)+locale.length+app.length+2);
			}
		});
	
	  }

	const determineRoute = () => {
		var url = window.location.href;

		setInvalidCodePage(false);
		setWelcomePage(false);
		setForgotPasswordPage(false);
		setForgotPasswordPageEmail(false);
		setForgotPasswordCodeEntry(false)
		setPasswordChangedPage(false);

		if (url.indexOf("/welcome/") > -1) {
			setWelcomePage(true);
			setLocalLoading(false);
			return Promise.resolve({});
		} else if (url.indexOf("/forgot-password/") > -1) {
			setForgotPasswordPage(true);
			setLocalLoading(false);
			return Promise.resolve({});
		} else if (url.indexOf("/password-reset-email/") > -1) {
			setForgotPasswordPageEmail(true);
			setLocalLoading(false);
			setRecoveryMethod("email");
			return Promise.resolve({});
			//TEMP FOR DEV!!!
		} else if (url.indexOf("/password-reset-sms/") > -1) {
			setForgotPasswordPage(true);
			setLocalLoading(false);
			setRecoveryMethod("mobile");
			return Promise.resolve({});
			//TEMP FOR DEV!!!
		} else if (url.indexOf("/password-code/") > -1) {
			setForgotPasswordCodeEntry(true);
			setLocalLoading(false);
			return Promise.resolve({});
		} else if (url.indexOf("/reset-password/") > -1) {
			setSetPasswordPage(true);
			setLocalLoading(false);
			return Promise.resolve({});
		} else if (url.indexOf("/password-changed") > -1) {
			setPasswordChangedPage(true);
			setLocalLoading(false);
			return Promise.resolve({});
		} else if (url.indexOf("/invalid-code") > -1) {
			setInvalidCodePage(true);
			setLocalLoading(false);
			return Promise.resolve({});
		} else if (url.indexOf("/invalid-wsfed") > -1) {
			console.log("invalid-wsfed so setting session storage");
			setLocalLoading(false);
			sessionStorage.setItem("3skye.failedWsFed", true);
			console.log(sessionStorage);
			switchPage('');
			return Promise.resolve({});
		}


		const i18nStart = url.indexOf("/", "https://x".length);
		const appStart = url.indexOf("/", i18nStart + 1) + 1;
		
		//appStart is +1, so here we are testing for -1 and url.length-1
		if (appStart === 0 || appStart === url.length) {
			//No app specified, find out the default
			console.log("No app specified, so polling configuration");
			return storage.getOrFetch("/configuration")
				.then(config => config.defaultApp)
				.then(app => {
					console.log("Rewriting url with app ", app);
					rewriteURL(app);
					return determineRoute();
				})
		}

		var appEnd = url.indexOf("/", appStart + 1);
		if (appEnd === -1) {
			//Need the trailing slash or things will be BAD
			url = url + "/"
			window.history.replaceState(null, window.document.title, url);
			appEnd = url.length - 1;
		}

		const app = url.substring(appStart, appEnd);
		//Internal routing...

		const route = appEnd === url.length ? "/" : url.substring(appEnd, url.length);

		return Promise.resolve({
			"app": app,
			"route": route
		});
	}

	const rewriteURL = (app) => {
		var url = window.location.href;

		//If we're here it's because there is no app specified, so it'll go on the end
		if (!url.endsWith("/")) {
			url += "/";
		}
		url += app + "/";
		window.history.replaceState(null, window.document.title, url);
	}

	// const authCheckerLogout = () => {
	// 	sessionStorage.clear();
	// 	sessionStorage.set("3skye.loggedin", false);
	// 	setLoggedIn(false);

	// 	storage.logout();
	// }


	if (welcomePage) {
		return <NotFound />
		const url = window.location.href;
		if (url.indexOf("/welcome/") > 0) {
			//it really should be!
			const email = url.substring(url.indexOf("/welcome/") + "/welcome/".length);
			return <WelcomePage switchPage={switchPage} email={email} />;
		}
		return <WelcomePage switchPage={switchPage} />;
	}

	if (forgotPasswordPage) {
		return <NotFound />
		return <ForgotPasswordSMS switchPage={switchPage} />;
	}

	if (setPasswordPage) {
		return <NotFound />
		return <SetPasswordPage switchPage={switchPage} />;
	}

	if (forgotPasswordPageEmail) {
		return <NotFound />
		return <ForgotPasswordEmail switchPage={switchPage} />;
	}

	if (forgotPasswordCodeEntry) {
		return <NotFound />
		return <ForgotPasswordCodeEntry switchPage={switchPage} recoveryMethod={recoveryMethod} />;
	}

	if (passwordChangedPage) {
		return <NotFound />
		return <PasswordChangedPage switchPage={switchPage} />;
	}

	if (invalidCodePage) {
		return <InvalidCodePage switchPage={switchPage} />;
	}

	if (localLoading) {
		return <div></div>;
	}

	if (maintenanceMode) {
		return <MaintenanceMode message={maintenanceMessage} />;
	}

	if (fourOhFour) {
		return <NotFound />;
	}

	//If logged in, don't need to know ... just go
	//TODO check restricted
	if (loggedIn || !loginRequired) {
		const { children } = props;

		const childrenWithProps = React.Children.map(children, child =>
			React.cloneElement(child, {
				app: app,
				logout: logout,
				...props
			})
		);

		return childrenWithProps;
	} else {
		const sessionFailed = sessionStorage.getItem("3skye.failedWsFed");
		if (sessionFailed) {
			return <UnauthorisedPage />
		} else {
			wsLogin();
			return <LoadingComponent visible />
		}
	}

}

export default withLoading(withI18n(withRemote(withStorage(withRouter(AuthChecker)))));
