import withRouteChange from "@threeskye/route-change";
import React, { Component } from "react";
import { withData } from "../../crm/ThreeSkyeCRM";
import ClientAccountToken from "./ClientAccountToken";
import ClientAccountTypes, { getClientAccountType } from "./getClientAccountType";

class ClientAccountLink extends Component {
	getLinkFuncMap = { [ClientAccountTypes.ACCOUNT]: this.props.getAccountLink, [ClientAccountTypes.CLIENT]: this.props.getClientLink, [ClientAccountTypes.NON_PERSON]: this.props.getNonPersonLink };

	constructor(props) {
		super(props);
		this.onClick = this.onClick.bind(this);
	}

	onClick(e) {
		e.stopPropagation()
        const { clientAccount, changeRoute } = this.props;
        if (this.props.onClick) {
            this.props.onClick();
        }
		if (!this.props.dontChangeRoute) changeRoute(this.getLinkFuncMap[getClientAccountType(clientAccount)](clientAccount));
	}

	render() {
		const { size, removable, clientAccount, margin, adviserGroupToken, showLocationLink, bookmarkClassNames, stylingId, customIcons, customIcon, accountNumber, tdWidth, isPrimary, stopClickEfect } = this.props;
		const clientAccountProps = { tdWidth, size, removable, clientAccount, margin, adviserGroupToken, showLocationLink, customIcons, customIcon, bookmarkClassNames, stylingId, accountNumber, isPrimary, stopClickEfect }; //update this if new props get added to client account token
		return <ClientAccountToken onClick={this.onClick} {...clientAccountProps} />;
	}
}

export default withData(withRouteChange(ClientAccountLink));
