import { withThreeSkyeGlobal } from '@threeskye/global';
import LoadingIcon from '@threeskye/loading-icon';
import React, { useEffect, useState } from 'react';
import { Redirect, withRouter } from 'react-router-dom';

const CredentialTransfer = props=>{
	const params = props.match.params;
	const { token } = params;
	const path = params[0];

	const remote = props.remote;

	const [ redirect, setRedirect ] = useState(null);

	useEffect(()=>{

		//This will install creds
		remote.get("/direct/" + token + "/modules/crm/redirect")
			.then(()=>{
				window.sessionStorage.setItem("3skye.loggedin", "true");
				setRedirect(path);
			});

	}, []);

	return redirect === null ? <LoadingIcon /> : <Redirect to={redirect} />
}

export default withRouter(withThreeSkyeGlobal(CredentialTransfer));