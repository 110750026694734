import React, { Component } from "react";
import "./EventDetailUpdates.scss";
import { withI18n } from "@threeskye/global";
import { isEmptyString, isEmpty } from "../../utils/Utils";
import moment from "moment";

// TODO: move these to a centralised config file.
const DATE_REGEX = /\d{4}-\d{2}-\d{2}/g;
const DATE_DISPLAY_FORMAT = "DD/MM/YYYY";

class EventDetailUpdateItem extends Component {

	validate(oldDetail, newDetail) {
		var data = this.fixEmpty(oldDetail, newDetail);
		// format the values e.g. date will be formatted as 15-08-2020
		data.oldDetail = this.format(data.oldDetail);
		data.newDetail = this.format(data.newDetail);
		return data;
	}

	format(value) {
		var self = this;
		if (typeof value === "string" && value.match(DATE_REGEX)) {
			return moment(value).format(DATE_DISPLAY_FORMAT);
		} else if (Array.isArray(value)) {
			 value.forEach(e=>{
				e.value = self.format(e.value);
			});
		}
		return value;
	}

	fixEmpty(oldDetail, newDetail) {
		if (Array.isArray(oldDetail) || Array.isArray(newDetail)) {
			//is array
			if (isEmpty(oldDetail)) {
				oldDetail = Array(newDetail.length).fill({});
			}
			if (isEmpty(newDetail)) {
				newDetail = Array(oldDetail.length).fill({});
			}
		}
		return { oldDetail, newDetail };
	}

	renderDetailsContainer(oldDetail, newDetail) {
		if (!oldDetail || !newDetail) {
			return null;
		}
		if (oldDetail.value === '' && newDetail.value === '') {
			return null;
		}
		//FIXME get a real key!
		return (
			<div className="details-container multi-details" key={Math.random()}>
				<div className="detail-update-container">
					<div className="detail-original">{oldDetail.value ? oldDetail.value : "[Empty]"}</div>
					<div className="detail-popup">{oldDetail.name}</div>
				</div>
				{/* <i className="material-icons">arrow_right_alt</i> */}
				<div className="detail-update-container">
					<div className="detail-update">{newDetail.value}</div>
					<div className="detail-popup">{newDetail.name}</div>
				</div>
			</div>
		)
	}

	renderSimpleDetails(oldDetail, newDetail) {
		return (
			<div className="details-container multi-details">
				<div className="detail-update-container">
					<div className="detail-original">{!isEmptyString(oldDetail) && !isEmptyString(oldDetail.toString) ? oldDetail.toString() : "[Empty]"}</div>
					<div className="detail-popup">{!isEmptyString(oldDetail) && !isEmptyString(oldDetail.toString) ? oldDetail.toString() : "[Empty]"}</div>
				</div>
				{/* <i className="material-icons">arrow_right_alt</i> */}
				<div className="detail-update-container">
					<div className="detail-update">{!isEmptyString(newDetail) && !isEmptyString(newDetail.toString) ? newDetail.toString() : "[Empty]"}</div>
					<div className="detail-popup">{!isEmptyString(newDetail) && !isEmptyString(newDetail.toString) ? newDetail.toString() : "[Empty]"}</div>
				</div>
			</div>
		)
	}

	render() {
		const i = this.props.i18n.get;
		let statusMessage = "";
		let statusIcon = "";
		
		if (this.props.status === "pending") {
			statusMessage = i("UpdatePending");
			statusIcon = <i className="material-icons update-pending">info</i>;
		} else if (this.props.status === "complete") {
			statusMessage = i("UpdateComplete");
			statusIcon = <i className="material-icons update-complete">check_circle</i>;
		}

		//TODO - fix source to send in array format so we don't need to do this!
		const { oldDetail, newDetail } = this.validate(this.props.oldDetail, this.props.newDetail);
		const renderDetails = Array.isArray(oldDetail)
			? oldDetail.map((val, idx) => this.renderDetailsContainer(oldDetail[idx], newDetail[idx]))
			: this.renderSimpleDetails(oldDetail, newDetail);

		return(
			<li className={`event-detail-update-item ${this.props.label ? "with label" : ""}`}>
				<div className="update-label-container">
					<div className="label">{this.props.label}</div>
					<div className="status-container">
						{statusIcon}
						<div className="status-popup">
							{statusMessage}
						</div>
					</div>
				</div>
				{renderDetails}

			</li>
		)
	}
}

export default withI18n(EventDetailUpdateItem);