import React, {Component} from 'react';
import {withI18n, withRemote, withStorage} from "@threeskye/global";
import Button from "@threeskye/buttons";

import './ForgotPasswordPage.scss';
import './LoginForm.scss';

import logo from './logo.png';
import { withCookies } from 'react-cookie';
import CRMErrorMessage from '../Shared/Validation/CRMErrorMessage';

class WelcomePage extends Component {

	refs = [];
	
	constructor(props) {
			super(props);

			this.state = {
					username: "",
					code: ["","","","","",""],
					retry: 3,
					key: "",
					step: 0,
					invalidCode: false,
					serverError: false

			}

			this.onChangeUsername = this.onChangeUsername.bind(this);
			this.onSubmitUsername = this.onSubmitUsername.bind(this);
			this.onSubmitSMSCode = this.onSubmitSMSCode.bind(this);
			this.onSubmitEmailCode = this.onSubmitEmailCode.bind(this);
			this.onCodeEnter = this.onCodeEnter.bind(this);
			this.handleKeyPress = this.handleKeyPress.bind(this);
			this.handleKeyDown = this.handleKeyDown.bind(this);

			this.initialise = this.initialise.bind(this);
			this.initialise();

			if (props.email && props.email.length > 0) {
				this.state.username = atob(props.email);
				this.onSubmitUsername();
			}
	}

	initialise() {
		
	}

	handleKeyPress(e, idx) {

		if (e.key === 'Enter') {
			if (this.state.step === 0 && this.state.username.length > 5) {
				this.onSubmitUsername();
			}
			if (this.state.step > 0) {
				const code = this.state.code.join('');
				if (code.length !== 6) {
					return;
				} else {
					this.onSubmitCode();
				}
			}
		}
	}

	handleKeyDown(e, idx) {
		if (e.key === 'Backspace') {
			if (this.state.code[idx].length > 0) {
				//do default
			} else {
				e.preventDefault();
				if (idx > 0) {
					this.refs[idx-1].focus();
				}
			}
		} else if (e.key === 'Enter') {
			switch (this.state.step) {
				case 1: this.onSubmitEmailCode(); break;
				case 2: this.onSubmitSMSCode(); break;
				default: break;
			}
		}
	}

	onChangeUsername(val) {
		this.setState({username: val});
	}

	onSubmitUsername() {
		const username = this.state.username;
		if (!username || username.length === 0)
			return;
		const data = {
			username
		};
		this.props.remote.post("/um/welcome", data)
			.then(x=>{
				this.setState({key: x.key, step: 1}, () => {this.refs[0].focus()});
			}, x=> {
				this.setState({invalidCode:true, retry:this.state.retry-1});
				if (this.state.retry <= 0) {
					this.props.switchPage('invalid-code');
				}
			}
		)
	}

	onSubmitCode() {
		const code = this.state.code.join('');
		if (code.length !== 6) {
			return;
		}

		const data = {
			username: this.state.username,
			code: code,
			key: this.state.key
		};

		const url = this.state.step === 1 ? "/um/welcome/2" : "/um/welcome/3";
		const step = this.state.step;

		this.props.remote.post(url, data)
		.then(x=>{
			this.setState(
				{key:x.key, step:step+1, code: ["","","","","",""], invalidCode:false, retry: 3},
				() => {this.refs[0] && this.refs[0].focus()});
			if (step === 2) {
				this.props.storage.put("password_change_key", this.state.key);
				this.props.storage.put("password_change_code", code);
				this.props.storage.put("username", this.state.username);

				this.props.switchPage('reset-password/');
			}

		}, x=>{
			//error
			if (x === 400) {
				this.setState({invalidCode:true, retry: this.state.retry - 1});
				if (this.state.retry <= 0) {
					this.props.switchPage('invalid-code');
				}
			} else
				this.setState({serverError: true});
		});
	}

	onSubmitSMSCode() {
		this.onSubmitCode();
	}
	onSubmitEmailCode() {
		this.onSubmitCode();
	}


	onCodeEnter(ref, val) {
		const code = this.state.code;
		code[ref] = val;
		this.setState({code: code});
		if (ref < this.state.code.length - 1 && val.length > 0) {
			this.refs[ref+1].focus();
		}
	}

	render() {
		const i = this.props.i18n.get;
		const invalidCode = this.state.invalidCode;
		let fieldValidationMessage;

		if (this.state.invalidCode) {
			fieldValidationMessage = i("CodeInvalid{" + this.state.retry + "}")//"Sorry, this code is invalid. Please try again. You have " + this.state.retry + " attempts remaining.";
		}
		if (this.state.serverError) {
			fieldValidationMessage = i("ServerError");
		}

		const codeEntryFields = [
			<input type="text" key={"field0"} ref={(ref) => this.refs[0] = ref} value={this.state.code[0]} onKeyDown={e=>this.handleKeyDown(e, 0)} onKeyPress={e=>this.handleKeyPress(e, 0)} onChange={(e)=>this.onCodeEnter(0, e.target.value)} className="input-field" maxLength="1" required/>,
			<input type="text" key={"field1"} ref={(ref) => this.refs[1] = ref} value={this.state.code[1]} onKeyDown={e=>this.handleKeyDown(e, 1)} onChange={(e)=>this.onCodeEnter(1, e.target.value)} className="input-field" maxLength="1" required/>,
			<input type="text" key={"field2"} ref={(ref) => this.refs[2] = ref} value={this.state.code[2]} onKeyDown={e=>this.handleKeyDown(e, 2)} onChange={(e)=>this.onCodeEnter(2, e.target.value)} className="input-field" maxLength="1" required/>,
			<input type="text" key={"field3"} ref={(ref) => this.refs[3] = ref} value={this.state.code[3]} onKeyDown={e=>this.handleKeyDown(e, 3)} onChange={(e)=>this.onCodeEnter(3, e.target.value)} className="input-field" maxLength="1" required/>,
			<input type="text" key={"field4"} ref={(ref) => this.refs[4] = ref} value={this.state.code[4]} onKeyDown={e=>this.handleKeyDown(e, 4)} onChange={(e)=>this.onCodeEnter(4, e.target.value)} className="input-field" maxLength="1" required/>,
			<input type="text" key={"field5"} ref={(ref) => this.refs[5] = ref} value={this.state.code[5]} onKeyDown={e=>this.handleKeyDown(e, 5)} onChange={(e)=>this.onCodeEnter(5, e.target.value)} className="input-field" maxLength="1" required/>,
		];

		const usernameFields = (
			<div id="content">
				<div id="welcome-message" className="content-wrapper">
					<h1 className="mb-1">Welcome to 3Skye</h1>
					<p>Before we get started, we need to make sure you're you. Please enter your email address and we'll send you a validation code.</p>
				</div>
				<div className="form modal-form">
					<div className="form-row">
						<div className="form-col-grow">
							<label htmlFor="email-address">Email Address</label>
							<input name="email-address" placeholder="person@email.com..." type="text" value={this.state.username} onKeyPress={e=>this.handleKeyPress(e, 0)} onChange={(e) =>this.onChangeUsername(e.target.value)}/>
						</div>
					</div>
					<div className="form-row mt-3">
						<div className="form-col-grow">
							<Button fullWidth icon="arrow_forward" onClick={this.onSubmitUsername}>Next Step</Button>
						</div>
					</div>
				</div>
			</div>
		);

		const emailCodeFields = (
			<div id="content">
				<div id="welcome-message" className="content-wrapper">
					<h1 className="mb-1">Welcome to 3Skye</h1>
					<p>A code has been emailed to you.	Please enter it here.</p>
				</div>
				<div className="form modal-form">
					<div className="form-row">
						<div className="form-col-grow">
							<CRMErrorMessage message={fieldValidationMessage} isValid={!invalidCode} shouldShow>
								<div className="validation-code">{codeEntryFields}</div>
							</CRMErrorMessage>
						</div>
					</div>
					<div className="form-row mt-3">
						<div className="form-col-grow">
							<Button fullWidth icon="arrow_forward" onClick={this.onSubmitEmailCode}>Next Step</Button>
						</div>
					</div>
				</div>
			</div>
		);

		const smsCodeFields = (
			<div id="content">
				<div id="welcome-message" className="content-wrapper">
					<h1 className="mb-1">Welcome to 3Skye</h1>
					<p>That's great.  Now we've sent a code to your mobile. Please enter it here.</p>
				</div>
				<div className="form modal-form">
					<div className="form-row">
						<div className="form-col-grow">
							<CRMErrorMessage message={fieldValidationMessage} isValid={!invalidCode} shouldShow>
								<div className="validation-code">{codeEntryFields}</div>
							</CRMErrorMessage>
						</div>
					</div>
					<div className="form-row mt-3">
						<div className="form-col-grow">
							<Button fullWidth icon="exit_to_app" onClick={this.onSubmitSMSCode}>Submit</Button>
						</div>
					</div>
				</div>
			</div>
		);

		const passwordSetFields = (
			<div>Not implemented!</div>
		)

		const formStepCount = this.state.step;
		const formStepContent = formStepCount === 0 ? usernameFields : formStepCount === 1 ? emailCodeFields : formStepCount === 2 ? smsCodeFields : passwordSetFields;

		return (
			<div id="master-container">
				<div id="header">
					<div className="logo-container">
							<img src={logo} alt="logo" className="logo" />
					</div>
				</div>
				{formStepContent}
			</div>
		);

	}
}

export default withCookies(withStorage(withRemote(withI18n(WelcomePage))));
